import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { setIsFetching } from '../actions';
import AnimatedRoute from '../components/AnimatedRoute';
import withTracker from '../wrappers/withTracker';
import OshiButton from '../components/Button';
import useFederalHealthPlanUILogic from '../hooks/useFederalHealthPlanUILogic';

/**
 * @module FederalHealthPlan/React.Component
 * @file /src/containers/FederalHealthPlan.js
 * @description Custom page that asks user if user has Medicare or Medicaid federal health plans.
 * @param {setIsFetching(state: boolean): void} setIsFetching - Redux store action-based function to show/hide loading mask screen
 * @returns {React.ReactElement}
 */
const FederalHealthPlan = ({ setIsFetching: setFetching }) => {
  const {
    animationDirection,
    setAnimationDirection,
    currentProgress,
    handleContinueBtnClick,
    handleOffboardingBtnClick,
  } = useFederalHealthPlanUILogic({ setIsFetching: setFetching });
  return (
    <AnimatedRoute
      nextRoute='/password'
      title='Do you have Medicare, Medicaid, or MassHealth?'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
      progressPercentage={currentProgress}
    >
      <Container>
        <ButtonContainer>
          <OshiButton onClick={handleOffboardingBtnClick}>Yes</OshiButton>
          <OshiButton onClick={handleContinueBtnClick}>No</OshiButton>
        </ButtonContainer>
      </Container>
    </AnimatedRoute>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > button {
    margin-bottom: 36px;
  }
  @media screen and (min-width: 570px) {
    flex-direction: row;
    & button {
      width: 48%;
    }
  }
`;

export default connect(null, { setIsFetching })(
  withTracker(FederalHealthPlan, 'Signup - Medicare/Medicaid - Page View')
);

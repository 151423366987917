import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { uiTypes } from '../constants';
import {
  regularSignupRouteList,
  genericSignupRouteList,
} from '../constants/routes';
import { getCurrentPageStep } from '../utils/routerHelper';

/**
 * @function useSigninPageUILogic
 * @description Custom hook that tracks URL location changes to calculate user's progress value to
 * complete their sign-up flow
 */
const useSignUpProgress = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isGenericSignupFlow = useSelector(
    (state) => state.uiReducer?.isGenericSignupFlow
  );
  const stepsList = isGenericSignupFlow
    ? genericSignupRouteList
    : regularSignupRouteList;

  React.useEffect(() => {
    const currentStep = getCurrentPageStep(stepsList, location.pathname);

    currentStep >= 0 &&
      dispatch({
        type: uiTypes.CHANGE_PROGRESS,
        payload: currentStep,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};

export default useSignUpProgress;

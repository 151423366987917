import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import OshiInput from '../components/OshiInput';
import ResetPasswordFlowContainer from '../components/ResetPasswordFlowContainer';

const validationSchema = Yup.object({
  passcode: Yup.string().required(
    'The code you entered is invalid or expired. Please try again.'
  ),
});

const ResetPasswordCode = () => {
  const location = useLocation();
  const history = useHistory();
  const [showErrors, setShowErrors] = useState(false);
  const error = location.state && location.state.error;

  useEffect(() => {
    if (error && !showErrors) {
      handleResetPasswordError();
    }
    // eslint-disable-next-line
  }, [error]);

  async function handleResetPasswordError() {
    await formik.setErrors({
      passcode: 'The code you entered is invalid or expired. Please try again.',
    });
    setShowErrors(true);
    document.getElementById('passcode').blur();
  }

  const handleOnKeyPress = async (evt) => {
    let isValid;
    if (evt.keyCode === 13) {
      evt.preventDefault();
      evt.target.blur();
      isValid = await formik.validateForm();
    }

    if (isValid && Object.keys(isValid).length === 0) {
      return handleOnSubmit();
    }

    return isValid && setShowErrors(isValid[Object.keys(isValid)[0]]);
  };

  const clearParentErrorOnFocus = () => {
    setShowErrors(false);
  };

  const handleOnSubmit = async () => {
    const validatedForm = await formik.validateForm();
    const passcode = formik.values.passcode.split(' ').join('');
    const email = location.state.email;

    if (!email) {
      console.log('no email', email);
      return history.replace('/reset-password-email');
    }

    if (validatedForm && Object.keys(validatedForm).length > 0) {
      return setShowErrors(true);
    }

    history.push({
      pathname: '/reset-password',
      state: {
        email,
        passcode,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      passcode: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleOnSubmit,
    validateOnChange: true,
  });

  const isValid = formik.dirty && Object.keys(formik.errors).length === 0;

  return (
    <ResetPasswordFlowContainer
      onKeyDown={handleOnKeyPress}
      headerText='Reset Password'
      title='If you entered a valid email, you will receive a password reset code. Please enter it below.'
      buttonText='Reset Password'
      errorText={formik.errors.passcode}
      errors={Object.keys(formik.errors).length > 0 && showErrors}
      onSubmit={handleOnSubmit}
      disabled={!formik.dirty}
      linkSegmentEvent='Sign In - Reset Password Code - Didn’t receive an email?'
      linkText='Didn’t receive an email?'
    >
      <OshiInput
        id='passcode'
        autoFocus
        type='tel'
        label='Reset Code'
        pattern='\d*' // only allow numbers
        mask='9 9 9 9 9 9'
        isValid={isValid}
        value={formik.values.passcode}
        onChange={formik.handleChange}
        segmentLabel='Sign In - Reset Password - Passcode Input'
        clearParentErrorOnFocus={clearParentErrorOnFocus}
        error={showErrors && formik.errors.passcode}
      />
    </ResetPasswordFlowContainer>
  );
};

export default ResetPasswordCode;

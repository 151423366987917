export const NEW_SUBSCRIPTION_STATUS = 'new';
export const SUBSCRIBED_SUBSCRIPTION_STATUS = 'subscribed';
export const INSURED_SUBSCRIPTION_STATUS = 'insured';
export const OPTUM_SUBSCRIPTION_STATUS = 'optum';
export const INSURED_FLOW = [
  INSURED_SUBSCRIPTION_STATUS,
  OPTUM_SUBSCRIPTION_STATUS,
];

export const PARTNERS_TYPE = {
  PARTNER: 'Partner',
  PAYER: 'Payer',
};

export const CURRENT_PARTNERS = {
  AEO: 'aeo',
  AETNA: 'aetna',
  UHC: 'uhc',
  OTHER: 'other',
  BLUE_CROSS: 'bluecrossma',
  FIREFLY: 'firefly',
  MARINER: 'mariner',
  CHILDRENS: 'childrens',
  RGS: 'rgs',
  TE: 'te',
};

export const FEDERAL_PARTNERS = {
  MEDICARE_MEDICAID_MASSHEALTH: 'medicare_medicaid_masshealth',
};

export const PARTNERS_BY_ORDER = [
  CURRENT_PARTNERS.AETNA,
  CURRENT_PARTNERS.UHC,
  CURRENT_PARTNERS.BLUE_CROSS,
  CURRENT_PARTNERS.FIREFLY,
  CURRENT_PARTNERS.OTHER,
];

export const ZERO_COST_PARTNERS = [
  CURRENT_PARTNERS.AEO,
  CURRENT_PARTNERS.CHILDRENS,
  CURRENT_PARTNERS.MARINER,
  CURRENT_PARTNERS.RGS,
  CURRENT_PARTNERS.TE,
];

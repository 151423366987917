import React from 'react';
import styled from 'styled-components';

import { palette } from '../theme/palette';

const Button = React.forwardRef(
  (
    {
      type,
      bgcolor,
      children,
      color,
      onClick,
      disabled,
      role = 'button',
      styles,
      btnAs = 'button',
      ...restProps
    },
    ref
  ) => {
    return (
      <Btn
        disabled={disabled}
        ref={ref}
        className={btnAs}
        type={type || 'button'}
        onClick={onClick}
        role={role}
        style={{
          backgroundColor: bgcolor || palette.melon,
          color: color || palette.melon800,
          opacity: disabled ? 0.4 : 1,
          ...styles,
        }}
        {...restProps}
      >
        <span>{children}</span>
      </Btn>
    );
  }
);

const Btn = styled.button`
  height: 41px;
  border-radius: 100px;
  min-width: 192px;
  border: none;

  /* ui/primary button */

  font-family: 'Usual';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height, or 121% */
  letter-spacing: 0.04em;
  text-transform: capitalize;

  font-feature- @media only screen and (min-width: 429px) {
    padding: 0 32px;
  }

  &.link {
    background: transparent;
    color: ${palette.coolGray};
    font-weight: 600;
    font-size: 14px;
    text-decoration-line: underline;
  }

  &.link:focus,
  &.link:active {
    background: #ffffff;
  }

  width: 100%;
  @media only screen and (min-width: 429px) {
    width: 183px;
  }
`;

export default Button;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import OshiLink from '../components/OshiLink';
import regex from '../utils/regex';
import getRepeatItemArray from '../utils/getRepeatItemArray';
import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import {
  pageViewEvent,
  userFullNameSubmitEvent,
  NamePageSigninBtnPressed,
} from '../actions/segment';
import { setShowBanner } from '../actions';
import useNamePageUILogic from '../hooks/useNamePageUILogic';
import OshiNextButton from '../components/OshiNextButton';

function Name({
  pageViewEvent: pageViewUIEvent,
  userFullNameSubmitEvent: userFullNameSubmit,
  setShowBanner,
  NamePageSigninBtnPressed,
}) {
  const { partnerName } = useParams();
  const history = useHistory();
  const {
    nextRoute,
    formik,
    isMobile,
    showErrors,
    animationDirection,
    setAnimationDirection,
    getTitle,
    clearParentErrorOnFocus,
    handleOnKeyPress,
    handleOnSubmit,
  } = useNamePageUILogic(
    partnerName,
    pageViewUIEvent,
    userFullNameSubmit,
    setShowBanner
  );
  const currentProgress = useSelector(
    (state) => state.uiReducer?.currentProgress
  );

  useEffect(() => {
    return () => {
      setShowBanner(false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <AnimatedRoute
      nextRoute={nextRoute}
      title={getTitle()}
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
      progressPercentage={currentProgress}
    >
      <Form onKeyDown={handleOnKeyPress}>
        <OshiInput
          id='firstName'
          autoFocus
          type='text'
          label='First Name'
          formik={formik}
          maskPlaceholder=''
          onChange={formik.handleChange}
          value={formik.values.firstName}
          clearParentErrorOnFocus={clearParentErrorOnFocus}
          segmentLabel='Sign Up - First Name'
          mask={getRepeatItemArray(regex.name, 50)}
          error={showErrors && formik.errors.firstName === showErrors}
        />

        <OshiInput
          id='lastName'
          type='text'
          label='Last Name'
          maskPlaceholder=''
          formik={formik}
          segmentLabel='Sign Up - Last Name'
          onChange={formik.handleChange}
          value={formik.values.lastName}
          clearParentErrorOnFocus={clearParentErrorOnFocus}
          mask={getRepeatItemArray(regex.name, 50)}
          error={showErrors && formik.errors.lastName === showErrors}
        />

        {showErrors && (
          <ErrorContainer>
            <Typography styles={{ color: palette.error }}>
              {showErrors}
            </Typography>
          </ErrorContainer>
        )}
      </Form>

      <OshiNextButton
        styles={{ width: isMobile ? '100%' : 183 }}
        buttonTitle={'Continue'}
        disabled={!formik.dirty && !formik.initialStatus.existingValues}
        onClick={handleOnSubmit}
      />

      <SignInContainer>
        <AccountText>Already have an account? </AccountText>
        <OshiLink
          styles={{ minWidth: 0, color: palette.navy500 }}
          buttonTitle='Sign in.'
          onClick={() => {
            NamePageSigninBtnPressed();
            history.push('/signin');
          }}
        />
      </SignInContainer>
    </AnimatedRoute>
  );
}

const Form = styled.form`
  width: 100%;
  flex: 1;
  @media only screen and (min-width: 429px) {
    flex: 0;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

const SignInContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: 'Usual', serif;
  color: ${palette.coolGray500};
  padding-top: 10px;
  flex: 0;
  @media only screen and (min-width: 429px) {
    flex: 1;
    align-items: flex-end;
  }
`;

const AccountText = styled.div`
  align-items: center;
  display: flex;
  height: 41px;
`;

export default connect(null, {
  pageViewEvent,
  userFullNameSubmitEvent,
  NamePageSigninBtnPressed,
  setShowBanner,
})(Name);

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import MultiChoiceButton from '../atoms/MultiChoiceButton';
import { localStorageGet, localStorageSave } from '../utils/localStorageHelper';
import { connect } from 'react-redux';
import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import withTracker from '../wrappers/withTracker';
import { PimidNo, PimidSubmitted } from '../actions/segment';
import OshiNextButton from '../components/OshiNextButton';

function PimID({ PimidNo, PimidSubmitted }) {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const [pimidInvalid, setPimidInvalid] = useState(false);
  const [disabled, setDisabled] = useState(true);
  let pimid = localStorageGet('pimid');

  Yup.addMethod(Yup.string, 'integer', function () {
    return this.matches(/^\d+$/, 'The field should have digits only');
  });

  const pimidValidationSchema = Yup.object({
    pimid: Yup.string()
      .required()
      .integer()
      .test((val) => {
        return val.toString().length > 7;
      }),
  });

  const formik = useFormik({
    initialStatus: { existingValues: pimid },
    initialValues: {
      pimid: pimid || '',
    },
    validationSchema: pimidValidationSchema,
    onSubmit: () => handleNext(),
  });

  useEffect(() => {
    if (
      formik.values.pimid.length > 0 &&
      formik.errors.pimid === 'The field should have digits only'
    ) {
      setPimidInvalid(true);
    } else {
      setPimidInvalid(false);
    }

    if (formik.values.pimid.length > 1 && formik.isValid && disabled) {
      setDisabled(false);
    }
    if (!disabled && !formik.isValid) {
      setDisabled(true);
    }
    // eslint-disable-next-line
  }, [formik]);

  function handleNext() {
    localStorageSave('pimid', {
      pimid: formik.values.pimid,
    });
    PimidSubmitted();
    return setAnimationDirection('unmount');
  }
  function proceed() {
    localStorageSave('pimid', '');
    PimidNo();
    return setAnimationDirection('unmount');
  }

  const EnterKeyPress = (evt) => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
      if (formik.values.pimid.length > 1 && formik.isValid && !disabled) {
        handleNext();
      }
    }
  };

  return (
    <AnimatedRoute
      nextRoute='/email'
      title='Please enter the ID code provided'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Form onKeyDown={EnterKeyPress}>
        <OshiInput
          id='pimid'
          type='tel'
          label='Code'
          maxChar={8}
          onChange={formik.handleChange}
          formik={formik}
          error={pimidInvalid}
          segmentLabel='Onboarding - Pim ID Page - PIM ID Field Clicked '
          value={formik.values.pimid}
        />
        <ErrorContainer>
          {pimidInvalid && (
            <div>
              <NoIdContainerError>
                <MultiChoiceButton
                  type={'button'}
                  isSelected={pimid === 'no pimid'}
                  onClick={proceed}
                  text='I don’t have one'
                />
              </NoIdContainerError>
              <Typography styles={{ color: palette.error }}>
                The code you entered is invalid. Please try again or choose “I
                don’t have one” to skip this for now.
              </Typography>
            </div>
          )}
        </ErrorContainer>
        {(!formik.dirty || formik.dirty) &&
        formik.values.pimid.length < 8 &&
        !pimidInvalid ? (
          <NoIdContainer>
            <MultiChoiceButton
              type={'button'}
              isSelected={pimid === 'no pimid'}
              onClick={proceed}
              text='I don’t have one'
            />
          </NoIdContainer>
        ) : null}
      </Form>
      {formik.values.pimid.length === 8 ? (
        <OshiNextButton
          buttonTitle='Continue'
          disabled={disabled}
          onClick={handleNext}
        />
      ) : null}
    </AnimatedRoute>
  );
}

const Form = styled.form`
  min-height: 330px;
  position: relative;
  bottom: 20px;
  width: 100%;
`;

const NoIdContainerError = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 32px;
  justify-content: center;
  position: relative;
`;
const NoIdContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  text-align: center;
  margin-top: 60px;
  justify-content: center;
`;

export default connect(null, { PimidNo, PimidSubmitted })(
  withTracker(PimID, 'Signup - PimId - Page View')
);

import { uiTypes, userTypes } from '../constants';

export function setIsFetching(isFetching) {
  return {
    type: uiTypes.IS_FETCHING,
    isFetching,
  };
}

export function setShowBanner(showBanner) {
  return {
    type: uiTypes.SHOW_BANNER,
    showBanner,
  };
}

export function setPatientProfile(patientProfile) {
  return {
    type: userTypes.STORE_PATIENT_PROFILE,
    patientProfile,
  };
}

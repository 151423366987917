import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useGenericSignUpFunctions from './useGenericSignUpFunctions';
import { localStorageGet } from '../utils/localStorageHelper';
import { FEDERAL_PARTNERS } from '../constants/subscriptionStatus';
import { ROUTES } from '../constants/routes';

/**
 * @module useFederalHealthPlanUILogic /hooks/useFederalHealthPlanUILogic
 * @description Custom hook for Federal Health Plan UI logic page
 * It handles current animation flow data, current progress value sign, and UI events when user clicks on yes or no buttons.
 * @param {setIsFetching(state: any): void} setIsFetching - Redux action function to show/hide loading mask screen
 * @param {(name: string): void} pageViewEvent - Analytics segment action function to trigger when UI page is loaded.
 * @returns {{
 *   animationDirection: 'mount' | 'unmount',
 *   currentProgress: number,
 *   setAnimationDirection: (state: string): void,
 *   handleOffboardingBtnClick(): void,
 *   handleContinueBtnClick(): void,
 * }}
 */
const useFederalHealthPlanUILogic = ({ setIsFetching }) => {
  const history = useHistory();
  const location = history.location;
  const {
    updatePatientLead,
    findSelectedPartnerByList,
  } = useGenericSignUpFunctions();
  const [animationDirection, setAnimationDirection] = React.useState('mount');
  const currentProgress = useSelector(
    (state) => state.uiReducer?.currentProgress
  );
  const state = localStorageGet('state');
  const { address } = localStorageGet('address');
  const { selectedPartner: partner } = localStorageGet('selectedPartner');

  const handleContinueBtnClick = React.useCallback(async () => {
    setIsFetching(true);
    try {
      if (location?.state?.partnerList && partner && address && state) {
        const { partnerList } = location.state;
        const foundPartner = findSelectedPartnerByList(partnerList, partner);

        const foundPartnerState =
          foundPartner?.operatingStates?.includes(state) || null;

        if (!foundPartnerState) {
          setIsFetching(false);
          history.push({
            pathname: ROUTES.OFF_BOARDING,
            state: {
              address: {
                ...address,
                stateName: state,
              },
              partner: {
                id: partner,
                name: foundPartner.display_name,
                notFoundState: true,
              },
            },
          });
          return;
        }
        await updatePatientLead({ partner, address });
        setAnimationDirection('unmount');
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, partner, address, state]);

  const handleOffboardingBtnClick = React.useCallback(() => {
    history.push({
      pathname: ROUTES.OFF_BOARDING,
      state: {
        address: {
          ...address,
          stateName: state,
        },
        partner: {
          id: FEDERAL_PARTNERS.MEDICARE_MEDICAID_MASSHEALTH,
          name: 'Medicare or Medicaid or MassHealth',
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, address, partner]);

  return {
    animationDirection,
    setAnimationDirection,
    currentProgress,
    handleContinueBtnClick,
    handleOffboardingBtnClick,
  };
};

export default useFederalHealthPlanUILogic;

import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { setShowBanner, setPatientProfile } from '../actions';
import usePatientAPI from '../hooks/usePatientAPI';
import usePatientProfile from '../hooks/usePatientProfile';
import { palette } from '../theme/palette';
import getUrlParameter from '../utils/getUrlParameter';
import OshiLink from './OshiLink';

function SignedInAs({
  styles,
  setShowBanner,
  setPatientProfile,
  signOutEvent,
}) {
  const history = useHistory();
  const native = getUrlParameter('platform', history) === 'native';
  const { patientProfile } = usePatientProfile();
  const { signOut } = usePatientAPI();

  if (!patientProfile || native) return null;

  async function handleSignOut() {
    try {
      signOutEvent && signOutEvent();
      await signOut();
      setPatientProfile(false);
      history.replace('/name');
      setShowBanner({
        sentiment: 'positive',
        text: `You’ve successfully signed out.`,
      });
    } catch (error) {
      throw new Error('signOut error', { cause: error });
    }
  }

  return (
    <Container style={styles}>
      <span>
        Signed in as{' '}
        {`${patientProfile.first_name} ${patientProfile.last_name}`}.
      </span>
      <OshiLink
        styles={{ minWidth: 0, color: palette.navy500 }}
        buttonTitle='Not you?'
        onClick={handleSignOut}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: 'Usual', serif;
  color: ${palette.coolGray500};
  padding-bottom: 10%;
`;

export default connect(null, { setShowBanner, setPatientProfile })(SignedInAs);

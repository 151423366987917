import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter as Router } from 'react-router-dom';

import Loader from './components/Loader';

import Routes from './routes';
import store from './store';
import {
  AppointmentContext,
  AvailableAppointmentsContext,
} from './utils/context';
import useWindowOrtientationChange from './hooks/useWindowOrientationChange';

export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISH_KEY
);

function App() {
  const [appointment, setAppointment] = useState({
    Appointment: false,
    AppointmentTime: '',
    AppointmentTimeNode: '',
    AppointmentDate: '',
    AppointmentFullDate: '',
    SubscriptionStatus: '',
  });
  const [availableAppointments, setAvailableAppointments] = useState({
    dates: [],
    times: {},
  });
  useWindowOrtientationChange();

  return (
    <Provider store={store}>
      <Loader />
      <AppointmentContext.Provider value={[appointment, setAppointment]}>
        <AvailableAppointmentsContext.Provider
          value={[availableAppointments, setAvailableAppointments]}
        >
          <Router>
            <Routes />
          </Router>
        </AvailableAppointmentsContext.Provider>
      </AppointmentContext.Provider>
    </Provider>
  );
}

export default App;

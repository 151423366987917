import { appointmentTypes } from '../constants';

const initialState = {
  availableAppointments: null,
  error: null,
};

const appointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case appointmentTypes.STORE_AVAILABLE_APPTS:
      return { ...state, availableAppointments: action.availableAppointments };
    case appointmentTypes.APPOINTMENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default appointmentReducer;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import SoleraMarpaiCustomTitle from '../components/SoleraMarpaiCustomTitle';
import useCookieProcessing from './useCookieProcessing';
import { localStorageSave, localStorageGet } from '../utils/localStorageHelper';
import { uiTypes } from '../constants';
import useWindowSize from './useWindowSize';

const useNamePageUILogic = (
  partnerName = null,
  pageViewEvent,
  userFullNameSubmitEvent,
  setShowBanner
) => {
  const dispatch = useDispatch();
  const [showErrors, setShowErrors] = useState(false);
  const [nextRoute, setNextRoute] = useState('/email');
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const didCompleteSignUpFlow = localStorageGet('didCompleteSignUpFlow');
  const { getCookieValue, getCookieKeyValuePair } = useCookieProcessing();
  const nameValidationSchema = Yup.object({
    firstName: Yup.string().required('Enter a first name'),
    lastName: Yup.string().required('Enter a last name'),
  });
  const { screenSize } = useWindowSize();
  const isMobile = screenSize.width < 429;
  const { firstName, lastName } = localStorageGet('name');
  const formik = useFormik({
    initialStatus: { existingValues: firstName && lastName },
    initialValues: {
      firstName: firstName || '',
      lastName: lastName || '',
    },
    validationSchema: nameValidationSchema,
  });

  const getTitle = () => {
    switch (partnerName) {
      case 'solera':
      case 'marpai': {
        return <SoleraMarpaiCustomTitle setShowBanner={setShowBanner} />;
      }
      default:
        return 'What’s your name?';
    }
  };

  useEffect(
    () => {
      // fire page view event only if the user had
      if (!didCompleteSignUpFlow) {
        pageViewEvent(`Signup - Name - Page View`);
      }
      localStorageSave('partner', { partner: partnerName ?? null });
      localStorageSave('selectedPartner', { selectedPartner: partnerName });
      dispatch({
        type: uiTypes.CHANGE_SIGN_UP_FLOW,
        payload: !partnerName,
      });
      // Save qstring cookie value into local storage
      localStorageSave('qstring', { qstring: '' });
      const qStringValue = getCookieKeyValuePair('qstring');
      if (qStringValue) {
        const extractedQString = getCookieValue(qStringValue);
        localStorageSave('qstring', {
          qstring: extractedQString,
        });
      }
    },
    // eslint-disable-next-line
    []
  );

  const clearParentErrorOnFocus = () => {
    setShowErrors(false);
  };

  const handleOnSubmit = () => {
    // errorsArray will only have a single
    // element so we can  use [0]
    // if there are errors we get the message from
    // that error coming from Formik and set it as the error
    const errorsArray = Object.keys(formik.errors);
    if (errorsArray.length > 0) {
      return setShowErrors(formik.errors[errorsArray[0]]);
    }
    // TODO: for now this will be hardcoded to optum.
    // the reason is the mobile app is looking at subscription_stauts
    // as if it were "partner" when we do an overhaul there this will
    // need to be changed and we remove passing in a subscription_status
    localStorageSave('subscriptionStatus', {
      subscriptionStatus: 'optum',
    });

    if (partnerName === 'optum') {
      const pimIdPairValue = getCookieKeyValuePair('pimid');
      if (pimIdPairValue) {
        const extractedPimID = getCookieValue(pimIdPairValue);
        localStorageSave('pimid', { pimid: extractedPimID });
      } else {
        setNextRoute('/pimid');
      }
    }

    localStorageSave('name', formik.values);
    userFullNameSubmitEvent();
    return setAnimationDirection('unmount');
  };

  const handleOnKeyPress = (evt) => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
    }

    if (
      (evt.keyCode === 13 && formik.values.firstName.length > 0) ||
      formik.values.lastName.length > 0
    ) {
      if (
        (evt.keyCode === 13 && formik.values.firstName === '') ||
        formik.values.lastName === ''
      ) {
        const errorsArray = Object.keys(formik.errors);
        setShowErrors(formik.errors[errorsArray[0]]);
      }

      if (!formik.errors.firstName && !formik.errors.lastName) {
        setShowErrors(false);
      }

      if (
        evt.keyCode === 13 &&
        !formik.errors.firstName &&
        !formik.errors.lastName
      ) {
        handleOnSubmit();
      }
    }
  };

  return {
    formik,
    handleOnSubmit,
    handleOnKeyPress,
    getTitle,
    setShowErrors,
    isMobile,
    nextRoute,
    showErrors,
    animationDirection,
    setAnimationDirection,
    clearParentErrorOnFocus,
  };
};

export default useNamePageUILogic;

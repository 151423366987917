import { localStorageGet, localStorageSave } from '../utils/localStorageHelper';
import { ZERO_COST_PARTNERS } from '../constants/subscriptionStatus';

const useCostInfoUILogic = () => {
  const getPartnerType = async () => {
    const { selectedPartner } = localStorageGet('selectedPartner');

    const foundPartner = ZERO_COST_PARTNERS.find(p => p.toLowerCase() === selectedPartner.toLowerCase());

    await localStorageSave('showCostInfoBanner', foundPartner || null);
    return foundPartner;
  };

  return { getPartnerType };
};

export default useCostInfoUILogic;

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useCostInfoUILogic from './useCostInfoUILogic';
import { Auth, API } from 'aws-amplify';
import { userTypes } from '../constants';

function usePatientProfile(native = false) {
  const { getPartnerType } = useCostInfoUILogic();
  const patientProfileRedux = useSelector(
    (state) => state.userReducer.patientProfile
  );
  const [patientProfile, setPatientProfile] = useState(patientProfileRedux);
  const dispatch = useDispatch();

  async function getPatientProfile() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const userId = cognitoUser.attributes.sub;
      const salesForcePatient = await API.get('oshiAPI', `/users/${userId}`);
      dispatch({
        type: userTypes.STORE_PATIENT_PROFILE,
        patientProfile: salesForcePatient,
      });
      setPatientProfile(salesForcePatient);

      getPartnerType(salesForcePatient.subscription_status);
      return salesForcePatient;
    } catch (error) {
      setPatientProfile(false);
      return error;
    }
  }

  useEffect(
    () => {
      if (!patientProfile && !native) getPatientProfile();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { patientProfile, getPatientProfile };
}
export default usePatientProfile;

import { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import regex from '../utils/regex';
import { UserContext } from '../utils/context';
import usePatientAPI from './usePatientAPI';
import usePatientProfile from './usePatientProfile';
import { useHistory } from 'react-router-dom';
import { localStorageSave } from '../utils/localStorageHelper';

const useSigninPageUILogic = ({
  setIsFetching,
  SignInSigninBtnPressed,
  SigninInvalidEmailOrPasswordEntered,
}) => {
  const history = useHistory();
  const [showErrors, setShowErrors] = useState(false);
  const { getPatientProfile, patientProfile } = usePatientProfile();
  const signInValidationSchema = Yup.object({
    email: Yup.string().required('Please enter a valid email.'),
    password: Yup.string().required('Please enter a password.'),
  });
  const { loggedIn } = useContext(UserContext);
  const { signIn, getAppointments } = usePatientAPI();

  useEffect(() => {
    if (loggedIn && patientProfile) {
      determineUsersNextRoute();
    }

    if (loggedIn && !patientProfile) {
      handleLoggedInUser();
    }
    // eslint-disable-next-line
  }, [loggedIn, patientProfile]);

  async function handleLoggedInUser() {
    const profile = await getPatientProfile();
    if (profile) {
      determineUsersNextRoute();
    }
  }

  async function determineUsersNextRoute() {
    setIsFetching(true);
    localStorageSave('subscriptionStatus', {
      subscriptionStatus: 'optum',
    });
    const appts = await getAppointments();

    localStorageSave('onboardingAppointmentStatus', appts.onboarding);

    if (appts.onboarding === 'none') {
      return history.replace(`first-appointment-book`);
    } else if (appts.onboarding === 'pending') {
      return history.replace('first-appointment-overview');
    } else if (appts.onboarding === 'finished') {
      return history.replace('first-appointment-overview');
    }
  }

  const handleOnSubmit = async () => {
    const email = formik.values.email.toLowerCase();
    const validatedForm = await formik.validateForm();
    SignInSigninBtnPressed();

    if (validatedForm && Object.keys(validatedForm).length > 0) {
      return setShowErrors(true);
    }

    setIsFetching(true);
    const userNotFoundError = `We could not recognize your email and/or password combination. Please try again.`;
    try {
      await signIn({
        email,
        password: formik.values.password,
      });
      await getPatientProfile();
      determineUsersNextRoute();
    } catch (error) {
      setIsFetching(false);

      formik.errors.email = userNotFoundError;
      formik.errors.password = userNotFoundError;
      SigninInvalidEmailOrPasswordEntered();
      return setShowErrors(true);
    }
  };

  function validate() {
    const errors = {};
    if (!regex.email.test(formik.values.email)) {
      errors.email = 'Please enter a valid email.';
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: signInValidationSchema,
    onSubmit: handleOnSubmit,
    validateOnChange: true,
    validate,
  });

  const clearParentErrorOnFocus = () => {
    setShowErrors(false);
  };

  const handleOnKeyPress = async (evt) => {
    let isValid;
    if (evt.keyCode === 13) {
      evt.preventDefault();
      evt.target.blur();
      isValid = await formik.validateForm();
    }

    if (isValid && Object.keys(isValid).length === 0) {
      return handleOnSubmit();
    }

    return isValid && setShowErrors(isValid[Object.keys(isValid)[0]]);
  };

  return {
    formik,
    handleOnSubmit,
    handleOnKeyPress,
    setShowErrors,
    showErrors,
    clearParentErrorOnFocus,
    history,
    determineUsersNextRoute,
  };
};

export default useSigninPageUILogic;

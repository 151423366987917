import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { palette } from '../theme/palette';
import { isMobile } from 'react-device-detect';

import OshiBanner from '../components/OshiBanner';
import useWindowSize from '../hooks/useWindowSize';
import { getHeadlineListUIComp, getTitleText } from '../utils/stringHelpers';

function AnimatedTitle({
  animationDirection,
  animationRef,
  resetAnimaions,
  progressPercentage,
  cameAfterBrowserBackButtonClick,
  cameAfterOshiHeaderBackButtonClick,
  title,
}) {
  const { screenSize } = useWindowSize();

  const getDeviceTypeByScreenWidthSize = React.useCallback(() => {
    if (screenSize.width >= 768) {
      return 'tablet';
    }
    return 'mobile';
  }, [screenSize]);

  function getAnime() {
    let anime;

    if (animationDirection === 'mount') {
      anime = {
        from: {
          opacity: 0,
          transform: cameAfterBrowserBackButtonClick
            ? 'translate3d(-100%,0,0)'
            : 'translate3d(100%,0,0)',
        },
        to: { opacity: 1, transform: 'translate3d(0%,0,0)' },
      };
    } else if (animationDirection === 'unmount') {
      anime = {
        from: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        to: {
          opacity: 0,
          transform: cameAfterOshiHeaderBackButtonClick
            ? 'translate3d(100%,0,0)'
            : 'translate3d(-100%,0,0)',
        },
      };
    }

    return anime;
  }

  const anime = getAnime();

  const titleAnime = useSpring({
    reset: resetAnimaions,
    ref: animationRef,
    ...anime,
  });

  function isTitleComponent() {
    return (
      typeof title === 'object' &&
      title?.$$typeof?.toString() === 'Symbol(react.element)'
    );
  }

  function getBanner() {
    const defaultStyles = {
      width: '100vw',
      marginTop: 0,
      marginBottom: 15,
    };
    if (isTitleComponent()) {
      defaultStyles.marginBottom = 0;
      defaultStyles.marginTop =
        getDeviceTypeByScreenWidthSize() === 'tablet' ? 32 : 16;
      defaultStyles.zIndex = 11;
    }
    return <OshiBanner styles={defaultStyles} />;
  }

  function getTitle() {
    if (isTitleComponent()) {
      return title;
    }
    return getHeadlineListUIComp(getTitleText(title, isMobile));
  }

  return (
    <TitleContainer
      progresspercentage={progressPercentage}
      isuicomponent={isTitleComponent().toString()}
      style={titleAnime}
    >
      {getBanner()}
      {getTitle()}
    </TitleContainer>
  );
}

const TitleContainer = styled(animated.div)`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: left;
  margin: ${({ isuicomponent }) =>
    isuicomponent === 'true' ? '0px' : '30px 0 42px'};
  font-family: 'Source Serif Pro', serif;
  font-size: 32px;
  color: ${palette.navy};
  line-height: 130%;
  font-weight: 700;
  width: 100%;
  @media only screen and (min-width: 429px) {
    margin-bottom: ${({ isuicomponent }) =>
      isuicomponent === 'true' ? '0px' : '42px'};
    font-size: 48px;
    margin-top: ${({ progresspercentage, isuicomponent }) =>
      isuicomponent === 'true' ? '0px' : '50px'};
  }
`;

export default React.memo(AnimatedTitle);

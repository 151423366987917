import { Auth, API } from 'aws-amplify';
import { localStorageGet } from '../utils/localStorageHelper';
import { getBirthdate } from '../utils/getBirthdate';

/**
 * Custom hook to handle API requests to user/patient data
 * @returns { Object } { updatePatient } - This method will update created patient profile data
 */
const usePatientAPI = () => {
  /**
   * An async function to process an API request to sign in method from AWS API endpoint
   * @param {Object} patientData - Patient profile object with email and password values set
   * @returns {AWSResponse|Error} - Successful response object if user could sign in successfully
   */
  const signIn = async ({ email, password }) => {
    try {
      return await Auth.signIn(email, password);
    } catch (error) {
      throw new Error('signIn error', { cause: error });
    }
  };
  /**
   * An async function to process an API request to sign out method from AWS API endpoint
   * @returns {AWSResponse|Error} - Successful response object if user could sign out successfully
   */
  const signOut = async () => {
    try {
      const response = await Auth.signOut();
      localStorage.clear();
      return response;
    } catch (error) {
      throw new Error('signOut error', { cause: error });
    }
  };
  /**
   * An async function to process an API request to sign up method from AWS API endpoint
   * @param {Object} patientData - Patient profile object with email, password, first name and last name values set
   * @returns {AWSResponse|Error} - Successful response object if user could sign up successfully otherwise throws an error exception
   */
  const signUp = async ({ email, password, firstName, lastName }) => {
    try {
      return await Auth.signUp({
        username: email,
        password,
        attributes: {
          email, // optional
          name: `${firstName} ${lastName}`,
        },
      });
    } catch (error) {
      throw new Error(`User couldn't sign up`);
    }
  };
  /**
   * An async function to process an API request to create Stripe account with patient data
   * @param {Object} patientData -  Patient profile object with email, first name, last name and user-sub from cognito values set
   * @returns {Object|Error} - Successful response object if user could create a stripe account successfully otherwise throws
   */
  const createStripeAccount = async ({
    userSub,
    firstName,
    lastName,
    email,
  }) => {
    try {
      return await API.post('oshiAPI', `/users/${userSub}/customer`, {
        body: {
          last_name: lastName,
          first_name: firstName,
          email,
        },
      });
    } catch (error) {
      throw new Error(`Stripe account couldn't be created`);
    }
  };
  /**
   * An async function that handles creating a patient profile data by sending saved user basic data, stripe id, cognito id,
   * subscription status, qstring and lead status.
   * @param {Object} patienData - Patient profile object with user basic info, cognito id, subscription status and others
   * @returns {Object|Error} - Returns API response object if API call was successful otherwise threws an error exception
   */
  const createPatient = async (patienData) => {
    // eslint-disable-next-line camelcase
    const {
      firstName,
      lastName,
      email,
      cognitoId,
      customerId,
      subscriptionStatus,
      pimdId,
      qstring,
      leadStatus,
      phase,
      state,
    } = patienData;
    const phone_number = localStorageGet('phone').replace(/[^A-Z0-9]/gi, '');
    const birthdate = getBirthdate();
    const { address } = localStorageGet('address');
    const gender = localStorageGet('gender');
    const ppAcceptedTimestamp = Date.now();
    // eslint-disable-next-line camelcase
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZoneData = {
      tos_accepted: ppAcceptedTimestamp,
      privacy_policy_accepted: ppAcceptedTimestamp,
      notice_privacy_accepted: ppAcceptedTimestamp,
      hipaa_release_accepted: ppAcceptedTimestamp,
      time_zone_name: timeZoneName,
    };
    const body = {
      first_name: firstName,
      last_name: lastName,
      email,
      cognito_id: cognitoId,
      customer_id: customerId,
      subscription_status: subscriptionStatus,
      pim_id: pimdId,
      qstring,
      lead_status: leadStatus,
      phase,
      state,
      phone_number,
      city: address.city,
      address1: address.streetAddress,
      zip: address.zip,
      gender,
      birthdate,
      ...timeZoneData,
    };

    if (address.apartment) {
      body.address2 = address.apartment;
    }

    try {
      return await API.post('oshiAPI', '/users', {
        body,
      });
    } catch (error) {
      throw new Error(`Patient creation couldn't be processed`);
    }
  };

  // update patient with any values
  const customPatientUpdate = async (body, userIdFromWebview) => {
    try {
      if (userIdFromWebview) {
        // we need to be able to update the patient from the webview
        // because of the symptoms update
        return await API.put('oshiAPI', `/users/${userIdFromWebview}`, {
          body,
        });
      } else {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.attributes.sub;
        return await API.put('oshiAPI', `/users/${userId}`, {
          body,
        });
      }
    } catch (error) {
      throw new Error(`Patient couldn't be updated`);
    }
  };

  async function updatePatientPhase(phase) {
    const user = await Auth.currentAuthenticatedUser();
    const userId = user.attributes.sub;

    try {
      await API.put('oshiAPI', `/users/${userId}`, {
        body: { phase },
      });
    } catch (error) {
      throw new Error(`Patient phase couldn't be updated`);
    }
  }

  async function getAppointments() {
    const user = await Auth.currentAuthenticatedUser();
    const userId = user.attributes.sub;
    try {
      const appointments = await API.get(
        'oshiAPI',
        `/cards/appointments/?cognito_id=${userId}`
      );
      return appointments;
    } catch (error) {
      throw new Error(`couldn't get appointments`, { cause: error });
    }
  }

  return {
    signIn,
    signUp,
    createStripeAccount,
    createPatient,
    updatePatientPhase,
    customPatientUpdate,
    getAppointments,
    signOut,
  };
};

export default usePatientAPI;

import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { palette } from '../theme/palette';

function AnimatedContent({
  animationDirection,
  animationRef,
  resetAnimaions,
  children,
  handleAnimationFinish,
  isFirstPage,
  style,
}) {
  const contentAnime = useSpring({
    reset: resetAnimaions,
    ref: animationRef,
    onRest: handleAnimationFinish,
    from: { opacity: animationDirection === 'mount' ? 0 : 1 },
    to: { opacity: animationDirection === 'mount' ? 1 : 0 },
  });

  return (
    <Container
      isfirstpage={isFirstPage.toString()}
      style={{ ...contentAnime, ...style }}
    >
      {children}
    </Container>
  );
}

const Container = styled(animated.div)`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: ${palette.lightTransparent};
  z-index: 10;
  padding: 24px 20%;
  @media only screen and (min-width: 992px) {
    padding: ${(props) =>
      props.isfirstpage === 'true' ? '24px 80%' : '24px 20%'};
  }
`;

export default AnimatedContent;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { palette } from '../theme/palette';

const ScrollComponent = React.forwardRef(
  (
    { labels, width, onSelect, initialSelect, onScrollSegment, testId },
    ref
  ) => {
    const [selected, setSelected] = useState('');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
      setSelected('');
    }, [labels]);

    useEffect(() => {
      if (initialSelect) {
        setSelected(initialSelect);
      }
    }, [initialSelect]);

    const clickHandler = (node) => {
      setSelected(node);
      onSelect(labels[node], node);
    };

    const onScroll = () => {
      if (!scrolled) {
        setScrolled(true);
        onScrollSegment();
      }
    };

    return (
      <Container data-testid={testId || ''} width={width} onScroll={onScroll}>
        {labels &&
          labels.map((appointmentDate, index) => {
            return (
              <Block
                key={
                  appointmentDate.available_from +
                  appointmentDate.provider_id +
                  index
                }
              >
                <Element
                  aria-labelledby={`dateId-${index}`}
                  role='button'
                  tabIndex={0}
                  ref={(el) => {
                    ref.current[index] = el;
                  }}
                  aria-pressed={selected === index}
                  onClick={() => clickHandler(index)}
                  highlighted={selected === index}
                >
                  <div id={`dateId-${index}`}>
                    {moment(appointmentDate.available_from).format('LT')}
                  </div>
                </Element>
              </Block>
            );
          })}

        {(!labels || labels.length <= 0) && (
          <NoTimesSection
            aria-live='polite'
            aria-describedby='noTimesAvailableId'
          >
            <NoTimesText
              type='subcopy'
              style={{ fontWeight: 300 }}
              id='noTimesAvailableId'
            >
              There are no times available for this day. Please choose another
              date.
            </NoTimesText>
          </NoTimesSection>
        )}
      </Container>
    );
  }
);

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 343px;
  overflow: auto;
  flex-wrap: nowrap;
  scroll-snap-type: x mandatory;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
  @media (min-width: 768px) {
    width: 170px;
    flex-direction: column;
  }
`;

export const Element = styled.div`
  align-items: center;
  font-weight: 600;
  text-align: center;
  font-size: 12px;
  font-family: Usual;
  line-height: 131.7%;
  letter-spacing: 0.06em;
  white-space: pre-line;
  width: 65px;
  height: 16px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.highlighted ? palette.navy500 : palette.turquoise50};
  color: ${(props) => (props.highlighted ? palette.navy50 : palette.navy500)};
  overflow: auto;
  cursor: pointer;
  padding: 12px 18px;
  &:focus-visible {
    background-color: ${palette.navy500};
    color: ${palette.navy50};
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  margin-right: 10px;
  @media (min-width: 768px) {
    margin-bottom: 10px;
    margin-right: 0;
  }
`;

const NoTimesSection = styled.section`
  display: flex;
  @media (max-width: 768px) {
    padding: 0 2%;
  }
`;

const NoTimesText = styled.p`
  text-align: center;
  color: ${palette.coolGray};
  font-size: 12px;
  font-family: Usual;
  font-weight: 400;
  line-height: 131.7%;
  letter-spacing: 0.06em;
  @media (max-width: 768px) {
    padding: 0 2%;
  }
`;

export default ScrollComponent;

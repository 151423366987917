import React from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';

import CheckCircle from '../atoms/CheckCircle';
import { RadioCheckMark } from '../assets/svgs';
import { palette } from '../theme/palette';

/**
 * @function OshiRadioButton
 * @description Generic and reusable UI radio button component that behaves with fundamental functionality
 * of radio-button-html-based element.
 * Its radio-button icon is customized with a check-mark svg-based UI component and it adds a hidden radio-input
 * based UI component to handle UI form functionality.
 * @param {React.ReactNode} children - Custom UI components to be shown as radio-button label component.
 * @param {string} name - Name text to handle linking UI form functionality with this component.
 * @param {string} value - Selected radio button value represented as text
 * @param {boolean} isChecked - Boolean value to verify is radio-button was checked or not.
 * @param {string} componentKey - Custom key text to handle DOM functionality of custom check-mark icon component.
 * @param {(value: any): void} onClick - UI event that gets called when this component is clicked and passes as argument its value.
 * @returns {React.ReactElement}
 */
const OshiRadioButton = ({
  onClick,
  name,
  value,
  isChecked,
  children,
  componentKey,
}) => {
  const animatedColor = useSpring({
    borderColor: isChecked ? palette.mediumTurqoise : palette.coolGray200,
  });
  return (
    <RadioBtnContainer onClick={onClick} style={{ ...animatedColor }}>
      <HiddenInput type='radio' name={name} value={value} />
      <CheckCircleContainer>
        <CheckCircle
          componentKey={componentKey}
          onClick={onClick}
          borderColor={palette.coolGray200}
          nonFilledColor={palette.white}
          isFilled={isChecked}
          isRounded={true}
        >
          <RadioCheckMark />
        </CheckCircle>
      </CheckCircleContainer>
      <LabelContainer>{children}</LabelContainer>
    </RadioBtnContainer>
  );
};

const RadioBtnContainer = styled(animated.div)`
  border: 1px solid;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  position: relative;
  margin: 5px 0;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px;
`;

const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const HiddenInput = styled.input`
  visibility: hidden;
  width: 0;
  height: 0;
`;

const CheckCircleContainer = styled.div`
  padding: 0 20px 0 0;
`;

export default OshiRadioButton;

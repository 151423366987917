import { useState, useEffect} from "react";
import { sessionStorageGet, sessionStorageSave } from "../utils/sessionStorageHelper";

const useExperiment = (experimentId) => {
  const [variant, setVariant] = useState(sessionStorageGet(experimentId));

  useEffect(() => {
    if (!variant) {
      (async () => {
        if (window.dataLayer) {
          await window.dataLayer.push({ event: 'optimize.activate' });
        }
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            const fetchVariant = window.google_optimize.get(experimentId);
            setVariant(fetchVariant);
            sessionStorageSave(experimentId, fetchVariant);
            clearInterval(intervalId);
          }
        }, 100);
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return variant;
}

export default useExperiment;
import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactGA from 'react-ga';

import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './aws-exports';
import runAxe from './react-axe.config';
import { setViewportHeight } from './utils/domHelpers';
import './index.css';

setViewportHeight();

ReactGA.initialize(process.env.GA_KEY);

runAxe();

Sentry.init({
  dsn:
    'https://b5d7a30867ea495898589e78f3da67d5@o429510.ingest.sentry.io/5376324',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.ENV || 'dev',
});


const googleOptimize = document.createElement('script');
googleOptimize.src = `https://www.googleoptimize.com/optimize.js?id=${process.env.REACT_APP_OPT_CONTAINER_ID}`;
document.head.appendChild(googleOptimize);

const googleTagManager = document.createElement('script');
googleTagManager.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GA_KEY}`;
document.head.appendChild(googleTagManager);

window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', process.env.GA_KEY);

Amplify.configure(config);
ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();

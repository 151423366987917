import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';

import { palette } from '../theme/palette';
import { getCurrentPageStep, getCurrentProgress } from '../utils/routerHelper';
import { regularSignupRouteList, genericSignupRouteList } from '../constants/routes';

function OshiProgressBar({ progressPercentage }) {
  const location = useLocation();
  const isGenericSignupFlow = useSelector(
    (store) => store.uiReducer?.isGenericSignupFlow
  );
  const stepsList = isGenericSignupFlow
    ? genericSignupRouteList
    : regularSignupRouteList;

  const previousStep = getCurrentPageStep(stepsList, location.pathname) - 1;
  const [fromWidth, setFromWidth] = useState(
    `${getCurrentProgress(previousStep, stepsList.length)}%`
  );
  const toWidth = `${progressPercentage}%`;

  const progressAnime = useSpring({
    from: {
      width: fromWidth,
    },
    to: { width: toWidth },
  });

  React.useEffect(() => {
    const currentStep = getCurrentPageStep(stepsList, location.pathname);
    const previousStep = currentStep - 1;

    setFromWidth(
      `${previousStep >= 0 ? getCurrentProgress(previousStep, stepsList.length) : 0}%`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ProgressContainer>
      <Progress style={progressAnime} progresspercentage={progressPercentage} />
    </ProgressContainer>
  );
}

const ProgressContainer = styled.div`
  width: 70%;
  background-color: ${palette.transitionScreen};
  border-radius: 90px;
  margin-top: 20px;
  @media only screen and (min-width: 768px) {
    margin-top: 40px;
    width: 100%;
  }
`;

const Progress = styled(animated.div)`
  width: ${(props) => props.progresspercentage || '0%'};
  height: 6px;
  background-color: ${palette.mediumTurqoise};
  border-radius: 90px;
`;

export default React.memo(OshiProgressBar);

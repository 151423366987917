/* eslint-disable import/prefer-default-export */
/**
 * @function setViewportHeight
 * @description Set the viewport height to the actual height of the device.
 * This solves the problem of calculating the viewport height for mobile devices
 * according to the following article:
 * https://ilxanlar.medium.com/you-shouldnt-rely-on-css-100vh-and-here-s-why-1b4721e74487
 */
export const setViewportHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

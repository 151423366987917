import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useZipEligibilityUILogic from './useZipEligibilityUILogic';
import { stateAbbreviations } from '../constants/stateAbbreviations';
import { localStorageSave, localStorageGet } from '../utils/localStorageHelper';
import { getBirthdate } from '../utils/getBirthdate';
import useAddressUIForm from './useAddressUIForm';
import usePatientLeadAPI from './usePatientLeadAPI';
import { ROUTES } from '../constants/routes';
import { PATIENT_LEAD_STATUSES } from '../constants/salesforceTypes';

/**
 * Custom hook to handle UI logic processing for /address UI page.
 *
 * @param { Object } uiLogicConfig - Set of properties/methods to handle for instance tracking segment analytics
 * and custom UI state changes.
 * @returns { Object } uiLogicParams - Set of properties/methods to handle all UI logic behind /address page
 * For this case, UI form logic and UI navigation logic configuration are sent back to UI component to be used in its
 * UI template.
 */
const useAddressPageUILogic = ({
  setIsFetching,
  userConfirmAddressSubmit,
  OffBoardingAvailableStateDebug,
}) => {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState(false);
  const [zipError, setZipError] = useState(false);
  const history = useHistory();
  const { availableStates, zipLookUp } = useZipEligibilityUILogic(
    setIsFetching
  );
  const { savePatientLead } = usePatientLeadAPI();
  const gender = localStorageGet('gender');
  const birthdate = getBirthdate();
  const isGenericSignupFlow = useSelector(
    (state) => state.uiReducer?.isGenericSignupFlow
  );
  const currentProgress = useSelector(
    (state) => state.uiReducer?.currentProgress
  );

  const handleSubmitFn = async (values) => {
    setIsFetching(true);
    try {
      const locationObject = await zipLookUp(values.zip);
      if (locationObject === 'invalid') {
        setZipError(true);
        setIsFetching(false);
        return;
      }
      const stateValue = stateAbbreviations[locationObject.state] || null;
      localStorageSave('state', stateValue);

      const currentAddress = {
        streetAddress: values.streetAddress,
        apartment: values.apartment,
        city: values.city,
        state: values.state,
        zip: values.zip,
        gender,
        birthdate,
      };

      localStorageSave('address', { address: currentAddress });

      OffBoardingAvailableStateDebug(locationObject.state, {
        availableStates,
        stateValue,
      });

      if (!isGenericSignupFlow && !availableStates.includes(stateValue)) {
        setIsFetching(false);
        history.push({
          pathname: ROUTES.OFF_BOARDING,
          state: {
            address: currentAddress,
          },
        });
        return;
      }

      if (!isGenericSignupFlow) {
        const leadUpdate = {
          status: PATIENT_LEAD_STATUSES.QUALIFIED,
          zip_code: values.zip,
          state: values.state,
          street: values.streetAddress,
          city: values.city,
          gender,
          birthdate,
        };

        if (values.apartment) {
          leadUpdate.address2 = values.apartment;
        }
        
        await savePatientLead('update', leadUpdate);
      }

      setIsFetching(false);
      userConfirmAddressSubmit();
      setAnimationDirection('unmount');
    } catch (error) {
      setIsFetching(false);
    }
  };
  const handleOnKeyPressFn = (evt, formik) => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
      if (!disabled && formik.isValid) {
        handleSubmitFn(formik.values);
      }
    }
  };
  const { formik, handleSubmit, handleOnKeyPress } = useAddressUIForm({
    handleSubmitFn,
    handleOnKeyPressFn,
  });

  const { errors: formErrors } = formik;
  const { zip: zipValue, streetAddress: addressValue } = formik.values;
  const zipLength = zipValue?.length;
  const addressLength = addressValue?.length;

  // this useEffect keeps track of error state used to manage error messages
  useEffect(() => {
    if (!errors && Object.keys(formErrors).length !== 0) {
      setErrors(true);
    }
    if (errors && !zipError && Object.keys(formErrors).length === 0) {
      setErrors(false);
    }
    if (zipError) {
      setErrors(true);
    }
    if (zipError && typeof zipLength === 'number' && zipLength < 5) {
      setZipError(false);
      setErrors(false);
    }
  }, [errors, formErrors, zipError, zipLength]);

  useEffect(() => {
    if (
      typeof addressLength === 'number' &&
      addressLength > 1 &&
      formik.isValid &&
      disabled
    ) {
      setDisabled(false);
    }
    if (!disabled && !formik.isValid) {
      setDisabled(true);
    }
  }, [addressLength, formik.isValid, disabled]);

  return {
    formik,
    zipError,
    disabled,
    errors,
    animationDirection,
    currentProgress,
    isGenericSignupFlow,
    setAnimationDirection,
    handleSubmit,
    handleOnKeyPress,
  };
};

export default useAddressPageUILogic;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { connect, useSelector } from 'react-redux';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';

import getAge from '../utils/getAge';
import InputErrorMessage from '../atoms/InputErrorMessage';
import { localStorageSave, localStorageGet } from '../utils/localStorageHelper';
import { palette } from '../theme/palette';
import withTracker from '../wrappers/withTracker';
import OshiNextButton from '../components/OshiNextButton';
import { userDOBSubmitEvent, userAgeMustBeAtLeast18Event } from '../actions/segment';

function Birthdate({ userAgeMustBeAtLeast18Event, userDOBSubmitEvent }) {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const [showErrors, setShowErrors] = useState(false);
  const { birthday } = localStorageGet('dob');
  const currentProgress = useSelector(
    (state) => state.uiReducer?.currentProgress
  );

  function dobValidate({ birthday }) {
    const errors = {};
    const splitBirthday = birthday.split(' / ').join('/').split('/');
    const month = Number(splitBirthday[0]);
    const day = Number(splitBirthday[1]);

    if (birthday.length < 14) {
      errors.length = true;
      return errors;
    }

    if (month < 1 || month > 12) {
      errors.month = true;
      return errors;
    }

    if (day < 1 || day > 31) {
      errors.day = true;
      return errors;
    }

    const age = getAge(birthday);

    if (age <= 0) {
      errors.length = true;
      return errors;
    }

    if (age < 18 || age > 140) {
      errors.age = true;
      return errors;
    }

    return errors;
  }
  const formik = useFormik({
    initialStatus: { existingValues: birthday },
    initialValues: {
      birthday: birthday || '',
    },
    validate: dobValidate,
    validateOnMount: birthday ? true : false,
    onSubmit: () => handleNext(),
  });

  function handleNext() {
    // errorsArray will only have a single
    // element so we can  use [0]
    // if there are errors we get the message from
    // that error coming from Formik and set it as the error
    const errorsArray = Object.keys(formik.errors);
    if (errorsArray.length > 0) {
      if (formik.errors.age) {
        const age = getAge(formik.values.birthday);
        age < 18 && userAgeMustBeAtLeast18Event();
      }
      return setShowErrors(true);
    }

    // store info in redux;
    // animate and transition
    localStorageSave('dob', formik.values);
    userDOBSubmitEvent();
    return setAnimationDirection('unmount');
  }

  function clearParentErrorOnFocus() {
    setShowErrors(false);
  }

  const isValid = formik.dirty && Object.keys(formik.errors).length === 0;

  const EnterKeyPress = (evt) => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
    }
    if (evt.keyCode === 13 && !isValid) {
      setShowErrors(true);
    } else if (showErrors) {
      setShowErrors(false);
    } else if (evt.keyCode === 13 && isValid) {
      handleNext();
    }
  };

  return (
    <AnimatedRoute
      nextRoute='/street-address'
      progressPercentage={currentProgress}
      title='What’s your date of birth?'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Form onKeyDown={EnterKeyPress}>
        <OshiInput
          id='birthday'
          autoFocus
          type='tel'
          pattern='\d*'
          label='Date of Birth'
          isValid={isValid}
          formik={formik}
          maskPlaceholder=''
          showCheckMark
          onChange={formik.handleChange}
          segmentLabel='Patient Info - Birthday'
          value={formik.values.birthday}
          mask='99 / 99 / 9999'
          error={showErrors}
          clearParentErrorOnFocus={clearParentErrorOnFocus}
        />

        {!showErrors && (
          <ErrorsContainer>
            <InputErrorMessage
              value={formik.values.birthday}
              showCheckMark
              formik={formik}
              isValid={isValid}
              error='mm / dd / yyyy'
            />
          </ErrorsContainer>
        )}

        {showErrors && (
          <ErrorContainer>
            <div style={{ marginLeft: '0px' }}>
              <InputErrorMessage
                value={formik.values.birthday}
                showCheckMark
                formik={formik}
                isValid={isValid}
                error='mm / dd / yyyy'
              />
            </div>

            {!formik.errors.age && (
              <Subcopy style={{ marginTop: '12px', color: palette.error }}>
                Please enter a valid birthdate
              </Subcopy>
            )}

            <Subcopy
              style={{
                color: formik.errors.age
                  ? palette.error
                  : palette.darkSecondaryText,
                paddingTop: 38,
              }}
            >
              {`You must be at least 18 years of age to be eligible for Oshi
              Health’s services.`}
            </Subcopy>
          </ErrorContainer>
        )}

        {!showErrors && (
          <AgeMessageContainer>
            <Subcopy>
              {`You must be at least 18 years of age to be eligible for Oshi
              Health’s services.`}
            </Subcopy>
          </AgeMessageContainer>
        )}
      </Form>

      <OshiNextButton
        disabled={!formik.dirty && !formik.initialStatus.existingValues}
        onClick={handleNext}
        buttonTitle='Continue'
      />
    </AnimatedRoute>
  );
}

const Form = styled.form`
  height: 190px;
`;

const Subcopy = styled.span`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 168%;
  /* or 20px */

  letter-spacing: 0.04em;
  font-feature-settings: 'liga' off;

  /* cool-gray / cool-gray-500 */

  color: ${palette.coolGray};
`;

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
`;

const AgeMessageContainer = styled.div`
  display: flex;
  margin-top: 38px;
  justify-content: center;

  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

export default connect(null, { userDOBSubmitEvent, userAgeMustBeAtLeast18Event })(
  withTracker(Birthdate, 'Signup - DOB - Page View')
);

import { EventTypes } from 'redux-segment';
import { segmentTypes } from '../constants';
import { ONBOARDING_APPT_TYPE } from '../constants/appointment';

import {
  getPageNameByRoutePath,
  getPageNameSegmentTokenByRoutePath,
  parsedAppointmentType,
} from '../utils/segment';

export const createTrackEvent = (type, event, properties) => {
  const trackEvent = {
    type,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event,
        },
      },
    },
  };

  if (properties) {
    trackEvent.meta.analytics.eventPayload.properties = properties;
  }

  return trackEvent;
};

export const pageViewEvent = (name) => {
  const partner = localStorage.partner;
  return {
    type: segmentTypes.PAGE_VIEW_EVENT,
    meta: {
      analytics: {
        eventType: EventTypes.page,
        eventPayload: {
          name,
          properties: {
            partner,
          },
        },
      },
    },
  };
};

export const ValueProp = (valueProp) =>
  createTrackEvent('VALUE_PROP_EVENT', `Value prop ${valueProp} is viewed`);
export const inputLeaveEvent = (input) =>
  createTrackEvent(segmentTypes.INPUT_LEAVE_EVENT, input);
export const emailCheckLimitExceededEvent = (emailUsedToExceed) =>
  createTrackEvent(
    segmentTypes.EMAIL_CHECK_LIMIT_EXCEEDED_EXCEPTION,
    'Email check - LimitExceededException',
    { emailUsedToExceed }
  );

export const userFullNameSubmitEvent = () =>
  createTrackEvent(segmentTypes.USER_FULLNAME_SUBMIT, 'Signup - Name - Submit');

export const emailAlreadyInUseEvent = () =>
  createTrackEvent(
    segmentTypes.EMAIL_ALREADY_IN_USE,
    'Contact Information - Error - Email already in use'
  );

export const userEmailSubmitEvent = () =>
  createTrackEvent(
    segmentTypes.USER_EMAIL_SUBMIT,
    'Signup - Contact Information - Submit'
  );

export const userGenderSubmitEvent = () =>
  createTrackEvent(segmentTypes.USER_GENDER_SUBMIT, 'Signup - Sex - Submit');

export const userDOBSubmitEvent = () =>
  createTrackEvent(segmentTypes.USER_BIRTHDATE_SUBMIT, 'Signup - DOB - Submit');

export const userAddressSubmitEvent = () =>
  createTrackEvent(
    segmentTypes.USER_ADDRESS_SUBMIT,
    'Signup - Address - Submit'
  );

export const userConfirmAddressSubmitEvent = () =>
  createTrackEvent(
    segmentTypes.USER_CONFIRM_ADDRESS_SUBMIT,
    'Signup - Confirm Address - Submit'
  );

export const userPasswordSubmitEvent = () =>
  createTrackEvent(
    segmentTypes.USER_PASSWORD_SUBMIT,
    'Signup - Password - Submit'
  );

export const userPrivacyPolicySubmitEvent = () =>
  createTrackEvent(
    segmentTypes.USER_PRIVACY_SUBMIT,
    'Signup - Privacy - Submit'
  );

export const userBookAppoinmentCalendarSubmitEvent = () =>
  createTrackEvent(
    segmentTypes.USER_CALENDAR_SUBMIT,
    'Signup - Calendar - Submit'
  );

export const userConfirmAppointmentSubmitEvent = () =>
  createTrackEvent(
    segmentTypes.USER_REVIEW_APPT_DETAILS_SUBMIT,
    'Signup - Review Appointment Details - Submit'
  );

export const PimidSubmitted = () =>
  createTrackEvent('PIMID_SUBMITTED', 'Signup - PimID  - Submit');

export const userZipOffboardingSubmitEvent = () =>
  createTrackEvent(
    segmentTypes.USER_OFFBOARDING_SUBMIT,
    'Signup - Zip Offboarding - Submit'
  );

export const userBackButtonEvent = (
  routePath,
  appointmentType = ONBOARDING_APPT_TYPE,
  providerType = null
) => {
  const newAppointmentType = parsedAppointmentType(appointmentType);
  const pageName = getPageNameByRoutePath(routePath);
  const segmentToken = getPageNameSegmentTokenByRoutePath(routePath);

  return createTrackEvent(
    segmentTypes[`USER_${segmentToken}_BACK_BUTTON`],
    `${newAppointmentType} - ${
      providerType ? `${providerType} - ` : ''
    }${pageName} - Back Button`
  );
};

export const userZipOffboardingBackButtonEvent = () =>
  createTrackEvent(
    segmentTypes.USER_OFFBOARDING_BACK_BUTTON,
    'Signup - Zip Offboarding - Back Button'
  );

export const userTabActivenessEvent = (routePath, isHidden) => {
  const pageName = getPageNameByRoutePath(routePath);
  const segmentToken = getPageNameSegmentTokenByRoutePath(routePath);
  return createTrackEvent(
    isHidden
      ? segmentTypes[`USER_${segmentToken}_TAB_INACTIVE_EVENT`]
      : segmentTypes[`USER_${segmentToken}_TAB_ACTIVE_EVENT`],
    `Signup - ${pageName} - Tab ${isHidden ? 'Out of' : 'In'} Focus`
  );
};

export const userSubmitEvent = (
  routePath,
  appointmentType = ONBOARDING_APPT_TYPE,
  providerType = null
) => {
  const newAppointmentType = parsedAppointmentType(appointmentType);
  const pageName = getPageNameByRoutePath(routePath);
  const segmentToken = getPageNameSegmentTokenByRoutePath(routePath);

  return createTrackEvent(
    segmentTypes[`USER_${segmentToken}_SUBMIT`],
    `${newAppointmentType} - ${
      providerType ? `${providerType} - ` : ''
    }${pageName} - Submit`
  );
};

export const trackOnLoadedScreenEvent = (
  routePath,
  appointmentType = ONBOARDING_APPT_TYPE,
  providerType = null
) => {
  const newAppointmentType = parsedAppointmentType(appointmentType);
  const pageName = getPageNameByRoutePath(routePath);
  return pageViewEvent(
    `${newAppointmentType} - ${
      providerType ? `${providerType} - ` : ''
    }${pageName} - Page View`
  );
};

export const trackBookAnotherAppointmentEvent = (
  routePath,
  appointmentType = ONBOARDING_APPT_TYPE,
  providerType
) => {
  const newAppointmentType = parsedAppointmentType(appointmentType);
  const pageName = getPageNameByRoutePath(routePath);
  return createTrackEvent(
    segmentTypes.BOOK_ANOTHER_APPT_SELECTED,
    `${newAppointmentType} - ${providerType} - ${pageName} - Book Another Appointment Selected`
  );
};

export const trackBackToAppointmentsEvent = (
  routePath,
  appointmentType,
  providerType = null
) => {
  const newAppointmentType = parsedAppointmentType(appointmentType);
  const pageName = getPageNameByRoutePath(routePath);
  return createTrackEvent(
    segmentTypes.BACK_APPTS_SELECTED,
    `${newAppointmentType} - ${
      providerType ? `${providerType} - ` : ''
    }${pageName} - Back to Appointments Selected`
  );
};

export const userAgeMustBeAtLeast18Event = () =>
  createTrackEvent(
    segmentTypes.AGE_MUST_BE_AT_LEAST_18,
    'Sex/DOB - Error - Must be at least 18'
  );

export const RevealPasswordIconClick = () =>
  createTrackEvent(
    'REVEAL_PASSWORD_ICON',
    'Password Page_Reveal Password Icon Clicked '
  );
export const InvalidPassword = () =>
  createTrackEvent('INVALID_PASSWORD', 'Sign Up - Password - Did Not Meet Req');

export const LoadingAnimation = () =>
  createTrackEvent('LOADING_ANIMATION', 'Sign Up - Page View - Load Animation');

export const LandingGetStarted = () =>
  createTrackEvent('LANDING_GET_STARTED', 'Landing Page - Get Started');
export const LandingLogin = () =>
  createTrackEvent('LANDING_LOGIN', 'Landing Page - Login');
export const ConfirmAppointmentConfirmed = () =>
  createTrackEvent('APP_CONFIRM_CONFIRMED', 'First Appt Confirm - Confirmed');
export const ConfirmAppointmentReschedule = () =>
  createTrackEvent('APP_CONFIRM_RESCHEDULE', 'First Appt Confirm - Reschedule');
export const AppointmentDescriptionNext = () =>
  createTrackEvent('DESC_NEXT', 'First Appt Description Next');
export const AppointmentDescriptionSkipModal = () =>
  createTrackEvent('DESC_SKIP_MODAL', 'First Appt Description - Skip Modal');
export const AppointmentDescriptionGoBack = () =>
  createTrackEvent('DESC_BACK', 'First Appt Description - Go Back');
export const AppointmentDescriptionSkip = () =>
  createTrackEvent('DESC_SKIP', 'First Appt Description - Skip');
export const AppointmentCalendarInteraction = () =>
  createTrackEvent('BOOK_DATE', 'First Appt Book - Calendar D Interaction');
export const AppointmentBookTimeScroll = () =>
  createTrackEvent('BOOK_SCROLL_TIME', 'First Appt Book - Time Slot Scrolled');
export const AppointmentBookTimeSelect = () =>
  createTrackEvent('BOOK_SELECT_TIME', 'First Appt Book - Time Slot Chosen');
export const AppointmentBookBooked = (userStatus) =>
  createTrackEvent('BOOK_BOOKED', 'First Appt Book - Booked', userStatus);
export const AppointmentBookGoBack = () =>
  createTrackEvent('BOOK_BACK', 'First Appt Book - Go Back');
export const AppointmentOverviewNote = () =>
  createTrackEvent('NOTE_PROVIDER', 'First Appt Overview - Note to Provider');
export const AppointmentOverviewNoteSent = () =>
  createTrackEvent('NOTE_SENT', 'First Appt Overview - Note Sent');
export const AppointmentOverviewNoNoteSent = () =>
  createTrackEvent('NO_NOTE_SENT', 'First Appt Overview - No Note Sent');
export const AppointmentOverviewCoachBio = () =>
  createTrackEvent('COACH_BIO', 'First Appt Overview - See Bio');
export const AppointmentOverviewNextSteps = () =>
  createTrackEvent('COACH_BIO', 'First Appt Overview - Next Steps');

export const NamePageSigninBtnPressed = () =>
  createTrackEvent(
    segmentTypes.DESKTOPSIGNIN_NAME_PAGE_SIGN_IN_BUTTON_PRESSED,
    'Desktop Sign In - Name Page Sign In Button Pressed'
  );

export const SymptomsPageSignOutBtnPressed = () =>
  createTrackEvent(
    segmentTypes.DESKTOPSIGNIN_SYMPTOMS_PAGE_SIGN_OUT_BUTTON_PRESSED,
    'Desktop Sign In - Symptoms Page Sign Out Button Pressed'
  );

export const CalendarPageSignOutBtnPressed = () =>
  createTrackEvent(
    segmentTypes.DESKTOPSIGNIN_CALENDAR_PAGE_SIGN_OUT_BUTTON_PRESSED,
    'Desktop Sign In - Calendar Page Sign Out Button Pressed'
  );

export const SigninInvalidEmailOrPasswordEntered = () =>
  createTrackEvent(
    segmentTypes.DESKTOPSIGNIN_INVALID_EMAIL_OR_PASSWORD_ENTERED,
    'Desktop Sign In - Invalid Email or Password Entered'
  );

export const ForgotPasswordBtnPressed = () =>
  createTrackEvent(
    segmentTypes.DESKTOPSIGNIN_FORGOT_PASSWORD_BUTTON_PRESSED,
    'Desktop Sign In - Forgot Password Button Pressed'
  );

export const SignInCreateAccountBtnPressed = () =>
  createTrackEvent(
    segmentTypes.DESKTOPSIGNIN_SIGN_IN_CREATE_ACCOUNT_BUTTON_PRESSED,
    'Desktop Sign In - Create Account Button Pressed'
  );

export const SignInSigninBtnPressed = () =>
  createTrackEvent(
    segmentTypes.DESKTOPSIGNIN_SIGN_IN_BUTTON_PRESSED,
    'Desktop Sign In - Sign In Button Pressed'
  );

export const TrackToStripeCheckoutSession = () =>
  createTrackEvent(
    segmentTypes.TRACK_TO_STRIPE_CHECKOUT_SESSION,
    'Onboarding - Membership Summary Cash Pay'
  );
export const FeeScheduleModalView = () =>
  createTrackEvent(
    segmentTypes.FEE_SCHEDULE_MODAL_VIEWED,
    'Onboarding - Billing - Per Visit Fee Schedule'
  );
export const LeaveFeeScheduleModalView = () =>
  createTrackEvent(
    segmentTypes.LEAVE_FEE_SCHEDULE_MODAL_VIEWED,
    'Onboarding - Billing - Back to Membership Fees'
  );

export const TrackMembershipConfirmation = () =>
  pageViewEvent('Onboarding - Membership Confirmation');
export const TrackAppointmentTaken = (error) =>
  pageViewEvent(`Appointment Overview - Error - ${error}`);
export const TrackAppointmentTakenSheduled = () =>
  pageViewEvent('Onboarding - Appointment Taken Scheduled Page View');
export const ZipcodeEntry = () =>
  createTrackEvent(
    'ZIP_ENTRY',
    '	Onboarding - Patient Location - Zip Code Entry'
  );
export const ZipcodeInvalid = () =>
  createTrackEvent(
    'ZIP_INVALID',
    `Onboarding - Patient Location - Validation - Invalid Zip - State`
  );
export const ZipcodeNoCoverage = () =>
  createTrackEvent(
    'ZIP_INVALID',
    `Onboarding - Patient Info - Out of Coverage - State`
  );

export const OffBoardingAvailableStateDebug = (state, properties) =>
  createTrackEvent(
    'ZIP_INVALID',
    `Onboarding - EligibilityPage - AvailableStates - Debug`,
    properties
  );
export const OffBoardingDebug = (state, properties) =>
  createTrackEvent(
    'ZIP_INVALID',
    `Onboarding - Redirect to OffBoarding - State: ${state}`,
    properties
  );
export const ZipcodeSubmit = () =>
  createTrackEvent(
    'ZIP_SUBMIT',
    'Onboarding - Patient Location - Zip Code Submit'
  );

export const ZipcodOutside = () =>
  createTrackEvent(
    'ZIP_OUTSIDE',
    'Onboarding - Patient Info - Out of Coverage'
  );
export const SymptomsGiven = () =>
  createTrackEvent(
    'SYMPTOMS_GIVEN',
    'Patient Info Question - Symptom Page - Symptom Given'
  );
export const symptomsEnteredEvent = () =>
  createTrackEvent(
    segmentTypes.SYMPTOMS_ENTERED,
    'Signup - Review Appointment Details - Symptoms Entered'
  );
export const SymptomsNo = () =>
  createTrackEvent(
    'SYMPTOMS_NO',
    'Patient Info Question - Symptom Page - No Symptom'
  );
export const SymptomsSubmitted = () =>
  createTrackEvent(
    'SYMPTOMS_SUBMITTED',
    'Patient Info Question - Symptom Page - Symptom Submitted'
  );

export const PimidGiven = () =>
  createTrackEvent(
    'PIMID_FIELD_CLICKED',
    'Onboarding - Pim ID Page - PIM ID Field Clicked'
  );
export const PimidNo = () =>
  createTrackEvent('PIMID_NO', 'Onboarding - Pim ID Page - No PIM ID');

export const identifyPatientSegment = (userId, traits) => ({
  type: segmentTypes.IDENTIFY_PATIENT,
  meta: {
    analytics: {
      eventType: EventTypes.identify,
      eventPayload: {
        userId,
        traits,
      },
    },
  },
});

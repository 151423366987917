import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import OshiInput from '../components/OshiInput';
import { HeaderLogoBig } from '../assets/svgs';
import { palette } from '../theme/palette';
import useSigninPageUILogic from '../hooks/useSigninPageUILogic';
import Button from '../components/Button';
import OshiLink from '../components/OshiLink';
import MarketingSVG from '../img/signin.svg';
import { setIsFetching } from '../actions';
import {
  SignInCreateAccountBtnPressed,
  SigninInvalidEmailOrPasswordEntered,
  ForgotPasswordBtnPressed,
  SignInSigninBtnPressed,
} from '../actions/segment';

const BackgroundSvg = () => (
  <svg
    width={'100%'}
    height={'100%'}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M-46.377 357c-149.86 0-306.857 17.852-366.623 26.778L-316.661 997H1583.36c17.84-189.233 42.82-560.201 0-530.209-53.52 37.489-198.03 120.502-398.74 131.213-198.758 10.608-570.731-102.214-663.693-130.409l-2.65-.804C429.966 440.013 140.949 357-46.377 357Z'
      fill='#23374D'
    />
    <path
      opacity={0.3}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.901 191.064c66.737 74.592 95.238 174.169 55.323 265.275-44.68 101.981-136.07 182.612-247.392 173.127-130.862-11.151-252.264-90.511-282.916-218.314-36.112-150.566-4.342-331.175 135.575-396.006 127.115-58.9 245.424 70.869 339.41 175.918Z'
      fill='#FFC4B1'
    />
  </svg>
);

const SignIn = ({
  setIsFetching,

  SigninInvalidEmailOrPasswordEntered,
  ForgotPasswordBtnPressed,
  SignInCreateAccountBtnPressed,
  SignInSigninBtnPressed,
}) => {
  const {
    formik,
    showErrors,
    clearParentErrorOnFocus,
    handleOnKeyPress,
    handleOnSubmit,
    history,
  } = useSigninPageUILogic({
    setIsFetching,
    SignInSigninBtnPressed,
    SigninInvalidEmailOrPasswordEntered,
  });
  const isValid = formik.dirty && Object.keys(formik.errors).length === 0;

  return (
    <Container>
      <FormContainer onKeyDown={handleOnKeyPress}>
        <HeaderContainer>
          <HeaderLogoBig />
        </HeaderContainer>

        <WelcomeContainer>
          <WelcomeText>Welcome back!</WelcomeText>

          <DigestiveCareText>
            The digestive care you deserve is just a few steps away.
          </DigestiveCareText>
        </WelcomeContainer>

        <InputsContainer>
          <InputContainer>
            <OshiInput
              id='email'
              autoFocus
              type='text'
              label='Email'
              isValid={isValid}
              value={formik.values.email}
              onChange={formik.handleChange}
              clearParentErrorOnFocus={clearParentErrorOnFocus}
              segmentLabel='Sign In - Email Input'
              error={showErrors && formik.errors.email}
            />
          </InputContainer>

          <InputContainer>
            <OshiInput
              id='password'
              type='password'
              label='Password'
              isValid={isValid}
              onChange={formik.handleChange}
              value={formik.values.password}
              segmentLabel='Sign In - Password Input'
              clearParentErrorOnFocus={clearParentErrorOnFocus}
              error={showErrors && formik.errors.password}
            />

            {Object.keys(formik.errors).length > 0 && showErrors && (
              <ErrorContainer>
                <ErrorText>
                  {formik.errors.email || formik.errors.password}
                </ErrorText>
              </ErrorContainer>
            )}
          </InputContainer>
        </InputsContainer>

        <ButtonsContainer>
          <Button disabled={!formik.dirty} onClick={handleOnSubmit}>
            Sign In
          </Button>

          <ForgotPasswordContaienr>
            <OshiLink
              styles={{
                marginTop: 20,
                color: palette.coolGray500,
              }}
              containerStyles={{
                justifyContent: 'center',
              }}
              buttonTitle='Forgot password'
              onClick={() => {
                ForgotPasswordBtnPressed();
                history.push('reset-password-email');
              }}
            />
          </ForgotPasswordContaienr>
        </ButtonsContainer>

        <NoAccountContaienr>
          <DigestiveCareText style={{ marginBottom: '0px' }}>
            No account? No problem.
          </DigestiveCareText>

          <ForgotPasswordContaienr>
            <OshiLink
              styles={{ color: palette.navy500 }}
              containerStyles={{
                justifyContent: 'center',
              }}
              buttonTitle='Create account.'
              onClick={() => {
                SignInCreateAccountBtnPressed();
                history.replace('/name');
              }}
            />
          </ForgotPasswordContaienr>
        </NoAccountContaienr>
      </FormContainer>

      <RightContainer>
        <SvgContainer>
          <BackgroundSvg />
        </SvgContainer>

        <RigthInnerContainerTop>
          <RigthInnerContainerTopText>
            <img src={MarketingSVG} alt='QR code' />
          </RigthInnerContainerTopText>
        </RigthInnerContainerTop>
      </RightContainer>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
`;

const FormContainer = styled.form`
  display: flex;
  flex: 1;

  flex-direction: column;
  align-items: center;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  margin-top: 96px;
  margin-bottom: 18%;
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WelcomeText = styled.h2`
  font-family: 'Source Serif Pro', serif;
  font-size: 32px;
  margin-bottom: 15px;
  color: ${palette.navy500};
`;

const DigestiveCareText = styled.p`
  font-family: 'Usual', serif;
  font-size: 15px;
  margin-bottom: 42px;
  color: ${palette.coolGray};
  line-height: 150%;
  text-align: center;
  @media (max-width: 500px) {
    max-width: 80%;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  margin-bottom: 15%;

  @media (max-width: 500px) {
    min-width: 90%;
    max-width: 90%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  margin-bottom: 15%;
  @media (max-width: 500px) {
    min-width: 90%;
  }
`;

const ForgotPasswordContaienr = styled.div`
  display: flex;
  width: 100%;
`;

const NoAccountContaienr = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20%;
`;

const InputContainer = styled.div``;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  padding-top: 113px;
  max-height: 100%;
  background: linear-gradient(
    to top,
    ${palette.navy700} 0%,
    ${palette.navy700} 40%,
    ${palette.navy500} 40%,
    ${palette.navy500} 100%
  );

  @media (max-width: 1135px) {
    display: none;
  }
`;

const SvgContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  bottom: 0;
`;

const RigthInnerContainerTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 2;
`;

const RigthInnerContainerTopText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 5px;
`;

const ErrorText = styled.div`
  color: ${palette.error};
  font-family: 'Usual', serif;
  font-size: 12px;

  @media (max-width: 500px) {
    text-align: center;
  }
`;

export default connect(null, {
  setIsFetching,

  SigninInvalidEmailOrPasswordEntered,
  ForgotPasswordBtnPressed,
  SignInCreateAccountBtnPressed,
  SignInSigninBtnPressed,
})(SignIn);

import React from 'react';
import styled from 'styled-components';
import { DocIcon } from '../assets/svgs';

import { palette } from '../theme/palette';

function OshiProviderInfoContainer({
  appointmentDate=null,
  title,
  subTitle = null,
  duration,
}) {
  return (
    <ProviderContainer>
      <AppointmentDetail>
        { appointmentDate && <ChipHolder>
          <Chip>
            <span>{appointmentDate.time}</span>
          </Chip>
          <Chip>
            <span>{appointmentDate.date}</span>
          </Chip>
        </ChipHolder> }

        <CardContainer>
          <CardImageContainer>
            <DocIcon />
          </CardImageContainer>

          <CardInfoTextContainer>
            <HeaderTextContainer>
              <Title>{title}</Title>
              { subTitle && <SubTitle>{subTitle}</SubTitle> }
            </HeaderTextContainer>

            <AppointmentTime>{`${duration} min`}</AppointmentTime>
          </CardInfoTextContainer>
        </CardContainer>
      </AppointmentDetail>
    </ProviderContainer>
  );
}

const ProviderContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

const AppointmentDetail = styled.div`
  background: ${palette.turquoise50};
  border: 1px solid transparent;
  border-radius: 8px;
  height: 96px;
  width: 100%;
  @media only screen and (min-width: 429px) {
  }
`;

const ChipHolder = styled.div`
  position: absolute;
  right: 0;
  top: -12px;
  display: flex;
  justify-content: flex-end;
  padding: 0 12px 0 0;
  & div:first-child {
    margin-left: 0;
  }
`;

const Chip = styled.div`
  padding: 4px 8px;
  min-width: 3px;
  background: ${palette.navy};
  border-radius: 24px;
  margin-left: 8px;

  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.025em;
  color: #fefefe;
`;
const CardContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  padding: 0 16px;
  align-items: center;
`;
const CardImageContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;
const CardInfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;
const Title = styled.span`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.06em;

  /* navy / navy-500 */
  color: ${palette.navy};
`;
const SubTitle = styled.span`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: ${palette.coolGray};
`;
const AppointmentTime = styled.span`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 131.7%;
  /* identical to box height, or 16px */
  letter-spacing: 0.025em;
  font-feature-settings: 'liga' off;
  color: ${palette.coolGray};
`;

export default React.memo(OshiProviderInfoContainer);

import React, { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';

import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import { ChevronDown2Icon } from '../assets/svgs';
import { localStorageGet } from '../utils/localStorageHelper';

const ITEM_HEIGHT = 40;
const MAX_ITEMS_SHOWN = 3;

/**
 * @function OshiSelect
 * @description Generic and reusable UI select component that behaves with fundamental functionality
 * of select-html-based element.
 * It shows a list of select-based options when available. By default, the size of option list is limited
 * to show at most three options, the list is scrollable if more than three items are present in it.
 * @param {React.ReactNode} children - Custom UI components to be shown as select-option-based component.
 * @param {string} name - Name text to handle linking UI form functionality with this component.
 * @param {string} label - Label text to be displayed on top of this component.
 * @param {string} placeholder - Custom placeholder text to be displayed as first unselectable option.
 * @param {string} storageKey - Custom key text to retrieve from Local Storage and display it as selectable option.
 * @param {(value: any): void} onClick - UI event that gets called when an select option is selected and passes option value data.
 * @param {styled.CSSObject} style - Custom CSS object style to customize this component root element.
 * @returns {React.ReactElement}
 */
const OshiSelect = ({
  children,
  name,
  label,
  placeholder = '',
  storageKey = null,
  onChange,
  style = {},
}) => {
  const [expand, setExpand] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(placeholder);
  const [selectedValue, setSelectedValue] = useState(() => {
    if (storageKey) {
      const data = localStorageGet(storageKey);
      if (data) {
        return data[storageKey];
      }
    }
    return null;
  });
  const countCmps = children?.length > 0 ? children?.length : 1;
  const expandHeight = countCmps * ITEM_HEIGHT;

  const expandableAnime = useSpring({
    config: { mass: 1, tension: 180, friction: 16 },
    height: expand
      ? `${
          countCmps > MAX_ITEMS_SHOWN
            ? ITEM_HEIGHT * MAX_ITEMS_SHOWN
            : expandHeight
        }px`
      : '0px',
  });

  const expandableOverflowAnime = useSpring({
    overflowY: countCmps > MAX_ITEMS_SHOWN ? 'auto' : 'initial',
  });

  const backgroundAnime = useSpring({
    backgroundColor: palette.background,
    borderColor: expand ? palette.mediumTurqoise : palette.coolGray500,
  });

  const arrowAnime = useSpring({
    marginTop: expand ? '6px' : '0px',
    transform: expand ? 'scaleY(-1)' : 'scaleY(1)',
  });

  const handleExpandList = () => {
    setExpand((v) => !v);
  };

  const handleListItemClick = (value) => {
    if (onChange) {
      onChange(value);
    }
    setSelectedValue(value);
    setExpand(false);
  };

  useEffect(() => {
    const foundItem =
      children.find((it) => it?.props?.value === selectedValue) || null;
    selectedValue && foundItem && setSelectedLabel(foundItem.props.label);
  }, [children, selectedValue]);

  return (
    <Container style={style}>
      <Typography
        type={'subcopy'}
        styles={{
          fontSize: '15px',
          lineHeight: '22.5px',
          color: palette.coolGray500,
        }}
      >
        {label}
      </Typography>
      <Select
        name={name}
        onClick={handleExpandList}
        style={{ ...backgroundAnime }}
      >
        <TitleContainer>
          <Typography
            type={'paragraph'}
            styles={{ fontWeight: '400', color: palette.navy500 }}
          >
            {selectedLabel}
          </Typography>
        </TitleContainer>
        <ChevronContainer style={arrowAnime}>
          <ChevronDown2Icon
            color={expand ? palette.mediumTurqoise : palette.coolGray500}
          />
        </ChevronContainer>
      </Select>
      {expand ? (
        <ExpandableList
          style={{ ...expandableAnime, ...expandableOverflowAnime }}
        >
          {children.length > 0 ? (
            React.Children.map(children, (c) =>
              React.cloneElement(c, {
                onClick: () => handleListItemClick(c.props.value),
              })
            )
          ) : (
            <Typography
              type={'paragraph'}
              key={'no_data'}
              styles={{
                color: palette.coolGray500,
                textAlign: 'center',
                padding: '8px 12px',
                fontWeight: '400',
              }}
            >
              No data
            </Typography>
          )}
        </ExpandableList>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
  width: 100%;
`;

const Select = styled(animated.div)`
  display: flex;
  position: relative;
  border-bottom: 2px solid;
  width: 100%;
  height: 41px;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  height: 41px;
  align-items: center;
`;

const ChevronContainer = styled(animated.div)`
  padding: 0 10px 0 20px;
`;

const ExpandableList = styled(animated.div)`
  display: flex;
  border: 2px solid;
  border-top: none;
  border-color: ${palette.coolGray200};
  flex-direction: column;
  height: 0px;
  width: 100%;
  background: ${palette.background};
`;

export default OshiSelect;

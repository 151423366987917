import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import GooglePlacesModal from '../components/GooglePlacesModal';
import withTracker from '../wrappers/withTracker';
import OshiNextButton from '../components/OshiNextButton';
import { userAddressSubmitEvent } from '../actions/segment';

function StreetAddress({ userAddressSubmitEvent: userAddressSubmit }) {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const [address, setAddress] = useState('');
  const [isModalActive, setIsModalActive] = useState(false);
  const [googleMapsValue, setGoogleMapsValue] = useState(null);
  const addressInputRef = useRef();
  const currentProgress = useSelector(
    (state) => state.uiReducer?.currentProgress
  );

  function handleNext() {
    // store info in redux;
    // animate and transition
    userAddressSubmit();
    setAnimationDirection('unmount');
  }

  function handleChange(e) {
    const { value } = e.target;

    setAddress(value);

    if (value.length >= 2) {
      setIsModalActive(true);
    }
  }

  function handleModalClose() {
    setIsModalActive(false);
    setAddress('');
    addressInputRef.current.focus();
  }

  const EnterKeyPress = (evt) => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
    }
    const { value } = evt.target;
    if (evt.keyCode === 13 && value.length >= 2) {
    }
  };

  return (
    <>
      <AnimatedRoute
        nextRoute='/address'
        progressPercentage={currentProgress}
        title='What’s your address?'
        animationDirection={animationDirection}
        setAnimationDirection={setAnimationDirection}
      >
        <Form onKeyDown={EnterKeyPress}>
          <OshiInput
            ref={addressInputRef}
            id='streetAddress'
            autoFocus
            type='text'
            label='Street Address'
            onChange={handleChange}
            segmentLabel='Patient Info - Street Address'
            value={address}
          />
        </Form>

        <OshiNextButton
          buttonTitle='Continue'
          disabled={true}
          onClick={handleNext}
        />
      </AnimatedRoute>

      {isModalActive && (
        <GooglePlacesModal
          initialInput={address}
          setAddress={setAddress}
          handleModalClose={handleModalClose}
          googleMapsValue={googleMapsValue}
          setGoogleMapsValue={setGoogleMapsValue}
        />
      )}
    </>
  );
}

const Form = styled.form`
  height: 155px;
  width: 100%;
`;

export default connect(null, {
  userAddressSubmitEvent,
})(withTracker(StreetAddress, 'Signup - Address - Page View'));

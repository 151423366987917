import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';

import Welcome from '../containers/Welcome';
import PrivacyPolicy from '../containers/PrivacyPolicy';
import Name from '../containers/Name';
import Email from '../containers/Email';
import Password from '../containers/Password';
import AccountCreated from '../containers/AccountCreated';
import BookAnAppointment from '../containers/BookAnAppointment';
import Sex from '../containers/Sex';
import Birthdate from '../containers/Birthdate';
import Address from '../containers/Address';
import StreetAddress from '../containers/StreetAddress';
import Skip from '../containers/Skip';
import OffBoarding from '../containers/OffBoarding';
import AccountCreationError from '../containers/AccountCreationError';
import CancelationPolicy from '../containers/CancelationPolicy';
import ResetPassword from '../containers/ResetPassword';
import ResetPasswordEmail from '../containers/ResetPasswordEmail';
import ResetPasswordCode from '../containers/ResetPasswordCode';

import AppointmentBook from '../containers/AppointmentBook';
import AppointmentOverview from '../containers/AppointmentOverview';
import AppointmentConfirm from '../containers/AppointmentConfirm';
import MembershipTransition from '../containers/MembershipTransition';
import MembershipCashPay from '../containers/MembershipCashPay';
import NetworkError from '../containers/NetworkError';
import DeeplinkHandle from '../containers/DeeplinkHandle';
import FourOhFour from '../containers/FourOhFour';
import ProtectedRoute from './ProtectedRoute';
import PimIdRoute from './PimIdRoute';
import useBrowserTabActiveness from '../hooks/useBrowserTabActiveness';
import { userTabActivenessEvent } from '../actions/segment';
import { UserContext } from '../utils/context';
import SignIn from '../containers/SignIn';
import HealthPlan from '../containers/HealthPlan';
import CurrentPartners from '../containers/CurrentPartners';
import useSignUpProgress from '../hooks/useSignUpProgress';
import NewPartner from '../containers/NewPartner';
import FederalHealthPlan from '../containers/FederalHealthPlan';

export const signUpRoutes = {
  '/name': true,
  '/name/optum': true,
  '/email': true,
  '/password': true,
  '/privacy-policy': true,
  '/gender': true,
  '/birthdate': true,
  '/street-address': true,
  '/address': true,
};

const Routes = ({ userTabActivenessEvent }) => {
  const [loggedIn, setIsLoggedIn] = useState(false);
  useBrowserTabActiveness(userTabActivenessEvent);
  useSignUpProgress();
  useEffect(() => {
    checkIfUserIsSignedIn();
  }, []);

  async function checkIfUserIsSignedIn() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      setIsLoggedIn(cognitoUser);
      return cognitoUser;
    } catch (error) {
      console.log('error', error);
      setIsLoggedIn(false);
      return false;
    }
  }

  return (
    <UserContext.Provider value={{ loggedIn, setIsLoggedIn }}>
      <Switch>
        <Route path='/cancelation-policy'>
          <CancelationPolicy />
        </Route>
        <Route path='/reset-password-email'>
          <ResetPasswordEmail />
        </Route>
        <Route path='/reset-password-code'>
          <ResetPasswordCode />
        </Route>
        <Route path='/reset-password'>
          <ResetPassword />
        </Route>
        <Route path='/signin'>
          <SignIn />
        </Route>
        <Route exact path='/name/:partnerName'>
          <Name />
        </Route>
        <Route exact path='/name'>
          <Name />
        </Route>
        <ProtectedRoute path='/pimid' childCmp={PimIdRoute} />
        <Route path='/email'>
          <Email />
        </Route>
        <Route path='/password'>
          <Password />
        </Route>
        <Route path='/privacy-policy'>
          <PrivacyPolicy />
        </Route>
        <Route path='/account-created'>
          <AccountCreated />
        </Route>
        <Route path='/gender'>
          <Sex />
        </Route>
        <Route path='/birthdate'>
          <Birthdate />
        </Route>
        <Route path='/street-address'>
          <StreetAddress />
        </Route>
        <Route path='/address'>
          <Address />
        </Route>
        <Route path='/health-plan'>
          <HealthPlan />
        </Route>
        <Route path='/current-partners'>
          <CurrentPartners />
        </Route>
        <Route path='/new-partner'>
          <NewPartner />
        </Route>
        <Route path='/has-federal-plan'>
          <FederalHealthPlan />
        </Route>
        <Route path='/get-symptom-relief'>
          <BookAnAppointment />
        </Route>
        <Route path='/first-appointment-book'>
          <AppointmentBook />
        </Route>
        <Route path='/first-appointment-overview'>
          <AppointmentOverview />
        </Route>
        <Route path='/first-appointment-confirm'>
          <AppointmentConfirm />
        </Route>
        <Route path='/membership-transition'>
          <MembershipTransition />
        </Route>
        <Route path='/membership-cash-pay'>
          <MembershipCashPay />
        </Route>
        <Route path='/skip'>
          <Skip />
        </Route>
        <Route path='/offboarding'>
          <OffBoarding />
        </Route>
        <Route path='/network-error'>
          <NetworkError />
        </Route>
        <Route path='/account-creation-error'>
          <AccountCreationError />
        </Route>
        <Route path='/go-to-app'>
          <DeeplinkHandle />
        </Route>
        <Route exact path='/'>
          <Welcome />
        </Route>
        <Route component={FourOhFour} />
      </Switch>
    </UserContext.Provider>
  );
};

export default connect(null, { userTabActivenessEvent })(Routes);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react-web';

import { palette } from '../theme/palette';
import OshiHeader from '../components/OshiHeader';
import withTracker from '../wrappers/withTracker';
import { ONBOARDING_APPT_TYPE } from '../constants/appointment';
import { CURRENT_PARTNERS } from '../constants/subscriptionStatus';
import useAvailableAppointments from '../hooks/useAvailableAppointments';
import useExperiment from '../hooks/useExperiment';

import animation1 from '../assets/did-you-know-animation.json';
import animation2 from '../assets/book-first-appt-animation.json';
import { LoadingAnimation } from '../actions/segment';
import { APPT_BOOK_ON_CALENDAR } from '../constants/experiments';
import { localStorageGet } from '../utils/localStorageHelper';

function BookAnAppointment(props) {
  const { setIsFetching } = props;
  const history = useHistory();
  const { selectedPartner } = localStorageGet('selectedPartner');
  const variant = useExperiment(APPT_BOOK_ON_CALENDAR) === '1' && selectedPartner === CURRENT_PARTNERS.UHC;
  const [animation, setAnimation] = useState(animation1);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [appointmentFetchComplete, setAppointmentFetchComplete] = useState(false);

  useEffect(() => {
    if (animationComplete && appointmentFetchComplete) {
      setIsFetching(false);
      history.push(variant ? 'first-appointment-confirm' : 'first-appointment-book');
    } else if (animationComplete && !appointmentFetchComplete) {
      props.LoadingAnimation();
      setIsFetching(true);
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationComplete, appointmentFetchComplete])

  const getQueryStringParams = () => {
    const queryStringParameters = {};
    queryStringParameters.appointment_type = ONBOARDING_APPT_TYPE;
    return queryStringParameters;
  }

  useAvailableAppointments({
    appointmentTypeFromWebview: ONBOARDING_APPT_TYPE,
    queryStringParameters: getQueryStringParams(),
    onFetchComplete: () => setAppointmentFetchComplete(true),
  });

  function onComplete() {
    setAnimation();

    if (animation === animation1) {
      setAnimation(animation2);
    } else {
      setAnimationComplete(true);
    }
  }

  return (
    <Container>
      <OshiHeader action={() => history.goBack()} />
      <Lottie
        options={{
          animationData: animation,
          loop: false,
        }}
        eventListeners={[{ eventName: 'complete', callback: onComplete }]}
      />
    </Container>
  );
}

const Container = styled.main`
  width: 100vw;
  height: 90vh;
  display: flex;
  flex: 1;
  background: ${palette.background};
  position: relative;
  justify-content: center;
`;

export default connect(null, {
  LoadingAnimation,
})(
  withTracker(
    BookAnAppointment,
    'Signup - Get Symptom Relief - Page View'
  )
);

/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { palette } from '../theme/palette';
import { getHeadlineListUIComp, getTitleText } from '../utils/stringHelpers';
import { HeroDocBlob, ZeroCostBlob, TealBlob, MelonBlob } from '../assets/svgs';

const SoleraMarpaiCustomTitle = ({
  title = 'Join <wbr /> Oshi Health.',
  setShowBanner,
}) => {
  React.useLayoutEffect(() => {
    setShowBanner({
      sentiment: 'information',
      text: (
        <BannerTextContainer>
          <Text>
            On-demand digestive care - at <ZeroCost>$0</ZeroCost> cost to you
          </Text>
        </BannerTextContainer>
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <InnerContainer>
        <HeadlineContainer>
          {!isMobile ? (
            getHeadlineListUIComp(getTitleText(title), MainHeadline)
          ) : (
            <MainHeadline>{title.replace('<wbr />', '')}</MainHeadline>
          )}
          <SubHeadline>
            It only takes a few minutes to sign up and book your first visit.
            Enter your name to get started.
          </SubHeadline>
        </HeadlineContainer>
        <BlobContainer>
          <DocBlob />
          <CostBlob />
          <SmallBlob />
          <BigBlob />
        </BlobContainer>
      </InnerContainer>
    </Container>
  );
};

const BannerTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Text = styled.p`
  color: ${palette.lightTransparent};
  font-family: 'Usual';
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  @media only screen and (min-width: 429px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
const ZeroCost = styled.span`
  color: ${palette.melon500};
`;
const Container = styled.div`
  flex-direction: column;
  display: flex;
  width: 100vw;
  align-items: center;
  background-color: ${palette.melon50};
  z-index: 10;
`;
const InnerContainer = styled.div`
  display: flex;
  max-width: 500px;
  width: 100%;
  flex-direction: row;
  z-index: 10;
`;
const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  z-index: 10;
  @media only screen and (min-width: 992px) {
    width: 360px;
    height: 218px;
    padding: 47px 0 42px;
  }
`;
const MainHeadline = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 31.2px;
  color: ${palette.navy500};
  @media only screen and (min-width: 992px) {
    font-size: 56px;
    line-height: 130%;
  }
`;
const SubHeadline = styled.p`
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  font-family: 'Usual';
  color: ${palette.coolGray500};
  @media only screen and (min-width: 429px) {
    font-size: 16px;
  }
`;
const BlobContainer = styled.div`
  position: absolute;
  display: flex;
  width: 434px;
  height: 307px;
  left: 50%;
  z-index: 5;
  @media only screen and (min-width: 992px) {
    left: 95%;
  }
`;
const DocBlob = styled(HeroDocBlob)`
  position: absolute;
  display: none;
  z-index: 2;
  @media only screen and (min-width: 992px) {
    display: flex;
    top -2%;
    left: -30%;
  }
`;
const CostBlob = styled(ZeroCostBlob)`
  position: absolute;
  display: none;
  z-index: 3;
  @media only screen and (min-width: 992px) {
    display: flex;
    top: -1%;
    left: -34%;
  }
`;
const SmallBlob = styled(TealBlob)`
  position: absolute;
  display: flex;
  top: -3%;
  left: 24%;
  z-index: 1;
  @media only screen and (min-width: 992px) {
    top: 27%;
  }
`;
const BigBlob = styled(MelonBlob)`
  position: absolute;
  display: flex;
  bottom: 6%;
  @media only screen and (min-width: 992px) {
    bottom: -25%;
    left: -2%;
  }
`;

export default SoleraMarpaiCustomTitle;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_mobile_analytics_app_id: '3c9b8ae7692645b391641e3bd8fa5a32',
  aws_mobile_analytics_app_region: 'us-east-1',
  Analytics: {
    AWSPinpoint: {
      appId: '3c9b8ae7692645b391641e3bd8fa5a32',
      region: 'us-east-1',
    },
  },
  aws_cloud_logic_custom: [
    {
      name: 'daemon',
      endpoint: 'https://522ehzury8.execute-api.us-east-1.amazonaws.com',
      region: 'us-east-1',
    },
    {
      name: 'oshiAPI',
      endpoint: 'https://emibxwnv1h.execute-api.us-east-1.amazonaws.com/devtwo',
      region: 'us-east-1',
    },
  ],
  aws_cognito_identity_pool_id:
    'us-east-1:2b974272-4ca4-4eaf-8427-10e6b908844e',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_C6Bj34nDU',
  aws_user_pools_web_client_id: '3jll714pm7kkkad6igviqc457t',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_dynamodb_all_tables_region: 'us-east-1',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'authsessions-devtwo',
      region: 'us-east-1',
    },
    {
      tableName: 'cacheddata-devtwo',
      region: 'us-east-1',
    },
    {
      tableName: 'extradata-devtwo',
      region: 'us-east-1',
    },
    {
      tableName: 'multimediameta-devtwo',
      region: 'us-east-1',
    },
    {
      tableName: 'patientactivities-devtwo',
      region: 'us-east-1',
    },
    {
      tableName: 'queueschedule-devtwo',
      region: 'us-east-1',
    },
  ],
  aws_user_files_s3_bucket: 'multimedia232837-devtwo',
  aws_user_files_s3_bucket_region: 'us-east-1',
};
export default awsmobile;

import React from 'react';
import styled from 'styled-components';

import {
  OSHI_INFO_BANNER_SENTIMENT,
  OSHI_INFO_BANNER_TYPE,
} from '../constants/ui';
import { palette } from '../theme/palette';
import { ErrorIcon, SuccessIcon } from '../assets/svgs';

const OshiInfoBanner = ({
  sentiment = null,
  title = '',
  description = '',
  type = OSHI_INFO_BANNER_TYPE.REGULAR,
  style = {},
}) => {
  return (
    <Container type={type} style={style}>
      <TitleContainer type={type} description={description}>
        {sentiment === OSHI_INFO_BANNER_SENTIMENT.SUCCESS ? (
          <SuccessIcon />
        ) : null}
        {sentiment === OSHI_INFO_BANNER_SENTIMENT.ERROR ? <ErrorIcon /> : null}
        <Title type={type} sentiment={sentiment}>
          {title}
        </Title>
      </TitleContainer>
      {description ? (
        <Description type={type}>{description}</Description>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  background: ${(props) =>
    props.type === OSHI_INFO_BANNER_TYPE.REGULAR
      ? palette.background
      : palette.turquoise50};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) =>
    props.type === OSHI_INFO_BANNER_TYPE.REGULAR && props.description
      ? '8px'
      : '0px'};
`;

const Title = styled.p`
  margin: 0px;
  margin-left: ${(props) => (props.sentiment ? '8px' : '0px')};
  font-family: 'Usual';
  font-size: ${(props) =>
    props.type === OSHI_INFO_BANNER_TYPE.REGULAR ? '15px' : '16px'};
  font-weight: ${(props) =>
    props.type === OSHI_INFO_BANNER_TYPE.REGULAR ? '600' : '400'};
  line-height: ${(props) =>
    props.type === OSHI_INFO_BANNER_TYPE.REGULAR ? '22.5px' : '24px'};
  color: ${(props) =>
    props.type === OSHI_INFO_BANNER_TYPE.REGULAR
      ? palette.navy600
      : palette.coolGray500};
`;

const Description = styled.p`
  margin: 0;
  font-family: 'Usual';
  font-size: ${(props) =>
    props.type === OSHI_INFO_BANNER_TYPE.REGULAR ? '14px' : '16px'};
  font-weight: ${(props) =>
    props.type === OSHI_INFO_BANNER_TYPE.REGULAR ? '400' : '600'};
  line-height: ${(props) =>
    props.type === OSHI_INFO_BANNER_TYPE.REGULAR ? '21px' : '24px'};
  color: ${(props) =>
    props.type === OSHI_INFO_BANNER_TYPE.REGULAR
      ? palette.coolGray500
      : palette.navy600};
`;

export default OshiInfoBanner;

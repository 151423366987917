/* eslint-disable default-case */
import React from 'react';
import styled from 'styled-components';

import { palette } from '../theme/palette';
import {
  HeaderLogoCicrle,
  CirlceLogo,
  HeaderLogo,
  ArrowRight,
  ArrowLeft,
  CloseIcon,
  SoleraOshiLogo,
  MarpaiOshiLogo,
} from '../assets/svgs';

function OshiHeader({ type, action, segment, testId, logoType = null, text }) {
  const backHandler = () => {
    action();
    if (segment) {
      segment();
    }
  };

  function getCenteredLogo() {
    if (text) {
      return <Text>{text}</Text>;
    }
    if (logoType === 'circle') {
      return <HeaderLogoCicrle />;
    }
    if (logoType === 'oshiLogo') {
      return <CirlceLogo />;
    }
    if (logoType === 'solera') {
      return <SoleraOshiLogo />;
    }
    if (logoType === 'marpai') {
      return <MarpaiOshiLogo />;
    }

    return <HeaderLogo />;
  }

  return (
    <Container data-testid={testId || ''}>
      <PaddedContainer>
        <LeftIconContainer onClick={backHandler}>
          {type === 'back' && <ArrowLeft color={palette.greyText} />}
        </LeftIconContainer>

        <LeftIconContainer onClick={backHandler}>
          {type === 'close' && <CloseIcon color={palette.greyText} />}
        </LeftIconContainer>

        {getCenteredLogo()}

        <RightIconContainer onClick={action}>
          {type === 'forward' && <ArrowRight color={palette.greyText} />}
        </RightIconContainer>
      </PaddedContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  z-index: 1;
  flex: 1;
  width: 100%;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  margin-top: 10px;
  @media (min-width: 768px) {
    margin-top: 30px;
  }
`;

const PaddedContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  margin: 0 15px;
  flex-direction: column;
  position: relative;
  max-width: 600px;
  align-items: center;
  justify-content: center;
`;

const LeftIconContainer = styled.div`
  position: absolute;
  left: 0;
  display: none;
  @media (max-width: 429px) {
    display: flex;
  }
`;

const RightIconContainer = styled.div`
  position: absolute;
  right: 0;
`;

const Text = styled.span`
  font-size: 16px;
  color: ${palette.navy500};
  font-family: 'Usual', sans-serif;
  font-weight: 600;
`;

export default OshiHeader;

import React, { useState } from 'react';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';

import AnimatedRoute from '../components/AnimatedRoute';
import { localStorageSave } from '../utils/localStorageHelper';
import withTracker from '../wrappers/withTracker';
import Button from '../components/Button';
import { userGenderSubmitEvent } from '../actions/segment';

function Sex({ userGenderSubmitEvent }) {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const currentProgress = useSelector(
    (state) => state.uiReducer?.currentProgress
  );

  async function handleNext(selection) {
    // store info in redux;
    // animate and transition
    localStorageSave('gender', selection);
    userGenderSubmitEvent();
    setAnimationDirection('unmount');
  }
  return (
    <AnimatedRoute
      nextRoute='/birthdate'
      progressPercentage={currentProgress}
      title='What’s your<wbr /><mbr /> legal sex?'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <PContainer>
        <Paragraph>
          We need this for health insurance purposes. You’ll get a chance to
          tell us your gender identity later.
        </Paragraph>
      </PContainer>

      <ButtonsContainer>
        <ButtonCotainer>
          <Button onClick={() => handleNext('male')}>Male</Button>
        </ButtonCotainer>

        <ButtonCotainer>
          <Button onClick={() => handleNext('female')}>Female</Button>
        </ButtonCotainer>
      </ButtonsContainer>
    </AnimatedRoute>
  );
}

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 576px) {
    flex-direction: row;
  }
`;

const ButtonCotainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  & ${Button} button {
    width: 100%;
  }
  @media only screen and (min-width: 429px) {
    margin-right: 24px;
    ${Button} button {
      width: 240px;
    }
  }
`;

const PContainer = styled.div`
  display: flex;
  max-width: 100%;
  margin-bottom: 114px;
  margin-top: -30px;

  @media only screen and (min-width: 429px) {
    margin-bottom: 42px;
  }
`;

const Paragraph = styled.p`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #66727f;
`;

export default connect(null, {
  userGenderSubmitEvent,
})(withTracker(Sex, 'Signup - Sex - Page View'));

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';

import usePatientProfile from './usePatientProfile';
import { FOLLOW_UP_APPT_TYPE } from '../constants/appointment';
import { segmentTypes } from '../constants';
import { appointmentTypes } from '../constants';
import { createTrackEvent } from '../actions/segment';
import { APPOINTMENT_ERROR } from '../constants/appointment';

function useAvailableAppointments(props) {
  const {
    native,
    appointmentTypeFromWebview,
    isReschedulingFromWebView,
    queryStringParameters,
    providerIdFromWebview,
    onFetchComplete,
  } = props;
  const history = useHistory();
  const availableAppointmentRedux = useSelector(
    (state) => state.appointmentReducer?.availableAppointments
  );
  const appointmentError = useSelector(
    (state) => state.appointmentReducer.error
  );
  const { patientProfile: patient } = usePatientProfile();
  const cognito_id = queryStringParameters.cognito_id || patient.cognito_id;
  const [availableAppointments, setAvailableAppointments] = useState(
    availableAppointmentRedux
  );
  const dispatch = useDispatch();

  if (!queryStringParameters.cognito_id) {
    queryStringParameters.cognito_id = patient.cognito_id;
  }

  const getApptsUrl =
    (native && appointmentTypeFromWebview === FOLLOW_UP_APPT_TYPE) ||
    isReschedulingFromWebView
      ? `/providers/${providerIdFromWebview}/availability`
      : '/providers/availability';

  const fetchFirstFourWeeksOfAvailability = async () => {
    try {
      const fetchedAppointments = await API.get('oshiAPI', getApptsUrl, {
        queryStringParameters,
      });

      return fetchedAppointments;
    } catch (error) {
      createTrackEvent(segmentTypes.SERVER_ERROR, `Server error`);
      history.push('network-error', { redirectLink: window.location.href });
    }
  };

  const fetchFifthAndSixthWeekOfAvailablity = async () => {
    const currentDate = new Date();

    let fromTime = new Date(
      Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 28
      )
    ).getTime();

    // time right now + 28 days + 14 days
    const toTimeBefore = new Date(
      currentDate.getTime() + (28 + 14) * 24 * 60 * 60 * 1000
    );

    // set to end of day
    const toTime = new Date(toTimeBefore.setHours(23, 59, 59, 999)).getTime();

    queryStringParameters.from = fromTime;
    queryStringParameters.to = toTime;

    try {
      const fetchedAppointments = await API.get('oshiAPI', getApptsUrl, {
        queryStringParameters,
      });

      return fetchedAppointments;
    } catch (error) {
      createTrackEvent(segmentTypes.SERVER_ERROR, `Server error`);
      history.push('network-error', {
        redirectLink: window.location.href,
      });
    }
  };

  async function getAvailableAppointments() {
    const firstFourWeeks = await fetchFirstFourWeeksOfAvailability();
    onFetchComplete();

    dispatch({
      type: appointmentTypes.STORE_AVAILABLE_APPTS,
      availableAppointments: [...firstFourWeeks],
    });

    setAvailableAppointments([...firstFourWeeks]);

    const fifthAndSixthWeeks = await fetchFifthAndSixthWeekOfAvailablity();

    dispatch({
      type: appointmentTypes.STORE_AVAILABLE_APPTS,
      availableAppointments: [...firstFourWeeks, ...fifthAndSixthWeeks],
    });

    setAvailableAppointments([...firstFourWeeks, ...fifthAndSixthWeeks]);
  }

  useEffect(() => {
    if (cognito_id) {
      !availableAppointments ||
      appointmentError === APPOINTMENT_ERROR.TIMESLOT_TAKEN
        ? getAvailableAppointments()
        : onFetchComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  return availableAppointments;
}

export default useAvailableAppointments;
